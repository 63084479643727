import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import AdminPanel from './AdminPanel';
import ConfigPanel from '../AdminTab/AdminView';
import { UserDataContext } from '../../index';

const styles = {
};

const useStyles = makeStyles(styles);


export default function ConfigurationView() {
  const classes = useStyles();

  const [userData,setUserData] = React.useContext(UserDataContext);

  let panel = "Ask Ekimetrics or the DSF to create new accounts, or put the prescription in production";
  if (["admin","DSF", "OAP-DMI/HairDiag", "lorealparis-dmi"].includes(userData["username"])){
    panel = <AdminPanel/>
  }

  return (
    <div>
      {panel}
      {/* <ConfigPanel/> */}
    </div>
  );
}
