import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from '@material-ui/core/styles';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ListSubheader from '@material-ui/core/ListSubheader';

const styles = theme => ({

    margin: {
      margin: theme.spacing(1),
    },

    title:{
      fontSize:16,
      fontWeight:400,
    },

    dialog:{
      fontSize:14,
      fontWeight:300,
    },

    formControl: {
      margin: 8,
      marginBottom:16,
      minWidth: "90%",
      fontSize:"12px",
    },
    selectEmpty: {
      marginTop: 8,
    },
    group:{
      fontWeight:700,
      color:"#00add0",
      fontSize:"14px",
    },
    menuItem:{
      fontSize:"14px",
    },
    inputLabel:{
      color:"#00add0"
    },


  });

const useStyles = makeStyles(styles);



function ProductSelector(props){


  const classes = useStyles();
  const [product, setProduct] = React.useState("");
  const [options, setOptions] = React.useState([]);

  const handleChange = event => {
    setProduct(event.target.value);
    props.onChange(props.step.routine_step,event.target.value,true)
  };


  React.useEffect(() => {
      let productOptions = [props.step.recommendation].concat(props.step.alternatives);
      productOptions = productOptions.map(el => el.product);
      setOptions(productOptions)
      setProduct(productOptions[0])
      props.onChange(props.step.routine_step,productOptions[0],false);
  }, [props.step])

  return (
  <FormControl className={classes.formControl}>
  <InputLabel shrink id="demo-simple-select-placeholder-label-label" className={classes.inputLabel}>
    {props.step.routine_step}
  </InputLabel>
  <Select
    labelId="demo-simple-select-placeholder-label-label"
    id="demo-simple-select-placeholder-label"
    value={product}
    onChange={handleChange}
    displayEmpty
    className={classes.selectEmpty}
  >
    <ListSubheader className={classes.group}>Recommended product</ListSubheader>
    <MenuItem className={classes.menuItem} value={options[0]}>{options[0]}</MenuItem>
    { options.length > 1 ? <ListSubheader className={classes.group}>Alternatives</ListSubheader>:""}
    {
      options.slice(1).map(el=>(
        <MenuItem className={classes.menuItem} value={el}>{el}</MenuItem>
      ))
    }
    <ListSubheader className={classes.group}>Other options</ListSubheader>
    <MenuItem className={classes.menuItem} value={"No product (empty step)"}>{"No product (empty step)"}</MenuItem>
    <MenuItem className={classes.menuItem} value={"Another product not in the list"}>{"Another product not in the list"}</MenuItem>
  </Select>
</FormControl>
  )

}



  


export default function FeedbackForm(props) {

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [text, setText] = React.useState("");
  const [selection,setSelection] = React.useState([]);

  React.useEffect(() => {
        setOpen(props.open);
    }, [props.open])


  function handleChange(event){
      setText(event.target.value);
  }

  function handleClose() {
    setOpen(false);
    props.setFeedbackOpen(false);
  }

  function validateFeedback(){




    if (text.length > 0){
      
      let selectionClean = Object.keys(selection).map(key=> ({"routine_step":key,"selection":selection[key]}));
      let productsClean = {}
      parseProducts().forEach(el => {
        productsClean[el.routine_step] = el.recommendation.product
      })

      let newSelectionClean = []
      selectionClean.forEach(el => {
        el["recommendation"] = productsClean[el.routine_step];
        el["has_changed"] = !(el["recommendation"] === el["selection"])
        newSelectionClean.push(el);
      })

      props.sendFeedback(text,false,newSelectionClean)
      handleClose()
    }
  }

  // Helper function to change state on dropdown change
  const handleFieldChange = (name , value, changeText) => {

    let oldValue = selection[name];
    let newSelection = selection;
    newSelection[name] = value;
    setSelection(newSelection);

    if (changeText){
      let newText = text;
      newText = newText + "\n" + `- Routine step "${name}" changed from "${oldValue}" to "${value}"`
      setText(newText);
    }
  }


  function parseProducts(){
    let steps = [];
    props.products.forEach(el => {
      steps = steps.concat(el.steps);
    })
    return steps;
  }



  return (
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="lg"
        fullWidth={true}
        aria-labelledby="form-dialog-title"
        className={classes.dialog}
        >
        <DialogTitle id="form-dialog-title" className={classes.title}>Feedback</DialogTitle>
        <DialogContent>
            <DialogContentText className={classes.dialog}>
            In order for Ekimetrics to optimize the prescription, can you please detail how this prescription could be improved
            </DialogContentText>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={4}>
                {
                  parseProducts().map(el => (<ProductSelector onChange={handleFieldChange} step={el}/>))
                }
              </Grid>
              <Grid item xs={12} sm={12} md={8}>            
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Feedback"
                  type="text"
                  fullWidth
                  multiline
                  rows="10"
                  onChange={handleChange}
                  value={text}
                  />
  
              </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} color="primary">
            Cancel
            </Button>
            <Button onClick={validateFeedback} color="primary">
            Give feedback
            </Button>
        </DialogActions>
      </Dialog>
  );
}
