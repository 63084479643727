import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Badge from '@material-ui/core/Badge';

// Custom
import MatchedInputs from './MatchedInputs';
import ProductTags from '../ProductsTab/ProductTags';

const useStyles = makeStyles(theme => ({

  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
  },

  header: {
    backgroundColor: "#011627",
    color: "white",
    padding: "4px !important",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
  },

  title: {
    textAlign: "center",
    fontSize: "12px !important",
    width: "100%",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
  },

  card: {
    minWidth: 170,
    maxWidth: 250,
    borderRadius: 8,
    margin:"auto",
  },

  cardContainer:{
    textAlign:"center",
    // margin:"auto",
  },

  bigAvatar: {
    borderRadius: "3px",
    textAlign: "center",
    margin:"auto",
    // margin:4,
    width:"100%",
    objectFit: "contain",
    maxWidth:60,
    // minWidth: "60",
    // marginRight:"8px",
  },

  bigAvatarDialog: {
    margin:"auto",
    borderRadius: "3px",
    textAlign: "center",
    height: 90,
    width: 90,
    objectFit: "contain",
  },

  productName: {
    textTransform: "uppercase",
    fontSize: "11px",
    textAlign: "center",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    margin:"auto",
  },

  chip:{
    margin:"4px",
  },

  cardContent: {
    cursor: "pointer",
    margin: "auto",
    // minHeight:"64px",
    padding:"8px !important",
    verticalAlign:"center",
  },

  margin: {
    margin: "0px",
    marginRight: theme.spacing(1),
  },

  centerVertical:{
    minHeight:"72px" // 64 for 2 rows
  }
}));




function ProductCard(props) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(null);
  // const tags = props.tags === undefined ? [] : props.tags; //.split(",");

  function handleClick(event) {
    setOpen(event.currentTarget);
  }

  function handleClose() {
    setOpen(null);
  }

  function chooseColor(weight){
    if (weight > 2){
      return {backgroundColor:"#00add0",color:"white"};
    } else if (weight > 1){
      return {backgroundColor:"#4cc5de",color:"black"};
    } else {
      return {backgroundColor:"#cceef5",color:"black"};
    }
  }

  return (
    <Grid item xs={12} sm={12} sm={props.cols} className={classes.cardContainer}>
      <Card className={classes.card}>
        <CardHeader
          title={
            <Typography
              variant="body2"
              noWrap
              align="justify"
              className={classes.title}
            >
              {props.header}
            </Typography>
          }
          className={classes.header}
        />
        <CardContent className={classes.cardContent} onClick={handleClick}>
            <Grid container spacing={1} direction="row" justify="center"alignItems="center" align="center" className={classes.centerVertical}>
              <Grid item xs={4} sm={4} md={4}>
                <Avatar
                  alt={props.name}
                  src={props.img}
                  className={classes.bigAvatar}
                />
              </Grid>
              <Grid item xs={8} sm={8} md={8}>
                <Typography className={classes.productName}>
                  {props.name}
                </Typography>
              </Grid>
            </Grid>
          {/* </Box> */}
        </CardContent>
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{props.name}</DialogTitle>
            <DialogContent>

              <Avatar
                alt={props.name}
                src={props.img}
                className={classes.bigAvatarDialog}
              />
              <DialogContentText>
                <p>This product has the following properties:</p>
                <ProductTags productName={props.name}/>
              </DialogContentText>
              <DialogContentText>
                {props.alternatives.length == 0
                ?
                <p>Which gives it a score <b>{props.score}</b>. There are no product alternatives for this routine step.</p>
                :
                <p>Which gives it a score <b>{props.score}</b>. The product alternatives are:</p>
                }
                <div style={{textAlign:"center"}}>
                  {
                  props.alternatives.map(el=>{
                    return (<Badge className={classes.margin} badgeContent={el.score} color="secondary">
                        <Chip size="small" label={el.product} className={classes.chip}/>
                      </Badge>
                    )
                  })
                }
                </div>

                <Box>
                  <MatchedInputs data={props.data}/>
                </Box>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Close
              </Button>
              {/* <Button onClick={handleClose} color="primary">
                Subscribe
              </Button> */}
            </DialogActions>
          </Dialog>
      </Card>
    </Grid>
  );
}

export default ProductCard;