import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import Badge from '@material-ui/core/Badge';
import Chip from '@material-ui/core/Chip';

// Custom Components
import { DataModelContext } from '../../layouts/Admin';


const styles = {
    paper: {
        padding: "8px",
        boxShadow:"none",
    },
    badge: {
        margin: "0px",
        marginRight: "8px",
        fontSize:"9px",
    },

    chip:{
        marginBottom:"8px",
        marginLeft:"4px"
    },

};
  
const useStyles = makeStyles(styles);

export default function ProductIdentity(props){

    // Styles
    const classes = useStyles();
    
    // Context
    const [dataModel,setDataModel] = React.useContext(DataModelContext);


    // Get data
    let tags;
    if (props.productName != null){
        const data = dataModel.getProductData(props.productName);
        tags = dataModel.safeGet(data,["tags"],[]);
    } else {
        tags = [];
    }


    return (
    <Paper className={classes.paper}>
        <div style={{textAlign:"center"}}>
        {
            tags.map(el => (
                <Badge className={classes.badge} badgeContent={el.weight} color="secondary">
                    <Chip 
                    size="small" 
                    label={el.tag_name} 
                    clickable         
                    className={classes.chip} 
                    />
                </Badge>

            ))
        }
        </div>
    </Paper>
    )

}