import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ImgLancome from "assets/img/model_lancome.png";
import LoadingOverlay from 'react-loading-overlay';
import { makeStyles } from "@material-ui/core/styles";
import cogoToast from 'cogo-toast';


import { UserAuthContext } from '../../index';
import { UserDataContext } from '../../index';

function MadeWithLove() {
  return (
    <Typography variant="body2" color="textSecondary" align="center" style={{fontWeight:300}}>
      Powered by <a href="https://ekimetrics.com">Ekimetrics.</a>
    </Typography>
  );
}

const styles = {
  root: {
    height: '100vh',
    fontWeight:300,
  },
  image: {
    backgroundImage: `url('${ImgLancome}')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: 24,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontWeight:"300 !important",
  },
  form: {
    width: '80%', // Fix IE 11 issue.
    marginTop:24,
    // marginTop: theme.spacing(1),
  },
  submit: {
    marginTop:24,
    fontWeight:300,
    backgroundColor:"#00add0"
    // margin: theme.spacing(3, 0, 2),
  },
}

const useStyles = makeStyles(styles);


function Login(){

  // States and context
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [overlay, setOverlay] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [userAuth,setUserAuth] = React.useContext(UserAuthContext);
  const [userData,setUserData] = React.useContext(UserDataContext);

  // Styles
  const classes = useStyles();


  // Methods
  const changeUsername = event => {
    setUsername(event.target.value)
  }

  const changePassword = event => {
    setPassword(event.target.value)
  }


  const login = (e) => {

    e.preventDefault();

    setOverlay(true);

    // Prepare API options and config
    var options = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Request-Headers': 'Content-Type, Authorization',
        // 'Access-Control-Allow-Origin':'*',
        // 'mode':'no-cors',
      },
    }

    // Parse inputs as user query
    let data = {"username":username,"password":password} 
    options["body"] = JSON.stringify(data);

    // API HTTP request
    console.log(process.env.REACT_APP_API_URL,options);
    fetch(process.env.REACT_APP_API_URL + 'app/auth',options)
    .then(response => response.json())
    .then(data => {
      console.log(data);
      if (data["authenticated"]){
        setOverlay(false);
        cogoToast.success('Successfuly authenticated as ' + username,{ position: 'bottom-left'});
        setUserData(data["data"]);
        setUserAuth(true);
    } else {
        cogoToast.error('Invalid username or password',{ position: 'bottom-left'});
        setErrorMessage("Invalid username or password")
        setOverlay(false);
      }
    })
    .catch(function(error) {
        setOverlay(false);
        cogoToast.error('There was an undefined error at the connection, please contact Ekimetrics',{ position: 'bottom-left'});
      console.log('Il y a eu un problème avec l\'opération fetch: ' + error.message);
    });


  };





    return (
    <LoadingOverlay
      active={overlay}
      spinner
      text='Attempting to login ...'
      >
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <Typography component="h1" variant="h5" style={{fontWeight:300}}>
              Eki.SkinDr
            </Typography>
            <Typography component="h1" variant="h5" style={{fontWeight:300}}>
              Login
            </Typography>
            <form className={classes.form} onSubmit={login}> 
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="text"
                label="Username"
                name="username"
                autoFocus
                onChange={changeUsername}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={changePassword}
              />
              {/* <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              /> */}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                // onClick={login}
              >
                Sign In
              </Button>
              <Typography variant="body1" style={{color:"red"}}>{errorMessage}</Typography>
              <Box mt={5}>
                <MadeWithLove />
              </Box>
            </form>
          </div>
        </Grid>
      </Grid>
    </LoadingOverlay>
    );
  }


export default Login;