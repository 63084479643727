import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import cogoToast from 'cogo-toast';
import Grid from '@material-ui/core/Grid';
import { EnvironmentContext } from '../../layouts/Admin';

// import listReactFiles from 'list-react-files'
// import { spring, AnimatedSwitch } from 'react-router-transition'; 

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    NavLink,
  } from "react-router-dom";
import MarkdownPage from "./MarkdownPage";





function importAll(r) {
    let files = {};
    r.keys().map((item, index) => { files[item.replace('./', '')] = r(item); });
    return files;
}

const markdowns = importAll(require.context('assets/docs', false, /\.(md)$/));

function cleanMarkdownName(x) {

    x = x.replace("-"," ");
    x = x[0].toUpperCase() + x.slice(1);
    return x;
  } 



  
function groupRoutes(routes){
    let newRoutes = {}
    routes.forEach(route => {
        console.log(newRoutes);
        if (route.category in newRoutes){
            newRoutes[route.category].push(route);
        } else {
            newRoutes[route.category] = [route]
        }
    })

    return newRoutes;
}



const routes = Object.keys(markdowns).sort().map(key => {

    let filepath = key;
    let brand="";
    let splitBrand = key.split("@");
    if (splitBrand.length == 2){
        brand = splitBrand[1].replace(".md","");
        key = splitBrand[0] + ".md";
    } else {
        key = splitBrand[0];
    }

    let split = key.split("_").slice(1);
    let path = "/" + split.join("/").replace(".md","");
    let name;
    let category;
    let group;
    
    if (split.length == 2){
        name = split[1].replace(".md","").slice(3);
        category = split[0];
        path = "/" + [category,name].join("/")
        path = path.replace(/\s/g,"-").toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        name = cleanMarkdownName(name.split("-").join(" "));
        category = cleanMarkdownName(category.split("-").join(" "));
        group = true;
    } else {
        name = cleanMarkdownName(split[0].replace(".md",""));
        category = cleanMarkdownName(split[0].replace(".md",""));
        group = false;
    }

    if (path == '/homepage'){
        path = '/'
    }

    return {
        path:path,
        name:name,
        category:category,
        filepath:filepath,
        group:group,
        brand:brand,
    }

})


console.log(routes);


const groupedRoutes = groupRoutes(routes);

console.log(groupedRoutes);

const styles = {
    link:{
        listStyleType: "none",
        fontSize:14,
        marginLeft:16,
    },
    linkDefault:{
        textDecoration:"none",
        color:"inherit",
        "&:visited": {
            color: "inherit",
            textDecoration:"none",
        },
    },

    groupLinks:{
        listStyleType: "none",
        fontSize:14,
        marginTop:8,
    },

    titleGroup:{
        fontWeight:700,
    },

    linkActive:{
        color:"#00ADD0 !important",
        // fontWeight:700,
        borderLeft: "0.2rem solid #00ADD0",
        paddingLeft:8,
    }

};

const useStyles = makeStyles(styles);







export default function ConfigurationView() {
  const classes = useStyles();

  const [env,setEnv] = React.useContext(EnvironmentContext);
  
  const [envRoutes,setEnvRoutes] = React.useState(groupedRoutes);

  React.useEffect(() => {

        let brand = env.split("-")[0];
        setEnvRoutes(groupRoutes(routes.filter(el => el.brand == brand || el.brand == "")));

    }, [env])



  return (
    <div>
      {/* <ReactMarkdown source={content}/> */}
      <Router>
      <div>
        <Grid container spacing={2}>
            <Grid item xs={12} sm = {3}>
            <ul>
                {
                    Object.keys(envRoutes).map(category=>{

                        let categoryRoutes = envRoutes[category];
                        let route;
                        if (categoryRoutes[0].group == false){
                            route = categoryRoutes[0]
                            return (
                            <li className={classes.link}>
                                <NavLink exact to={"/docs" + route.path} className={classes.linkDefault} activeClassName={classes.linkActive}>
                                    {route.name}
                                </NavLink>
                            </li>
                            )
                        } else {
                            return (
                                <div className={classes.groupLinks}>
                                    <li className={classes.titleGroup}>
                                        {category}
                                    </li>
                                    {
                                        categoryRoutes.map(route => (
                                        <li className={classes.link}>
                                            <NavLink exact to={"/docs" + route.path} className={classes.linkDefault} activeClassName={classes.linkActive}>
                                                {route.name}
                                            </NavLink>
                                        </li>
                                        ))
                                    }
                                </div>
                            )
                        }
                    })
                }
            </ul>
            </Grid>
            <Grid item xs={12} sm={9}>
                <Switch>
                {
                    routes.map(route=>{

                        // let title = route.path.slice(1);
                        // if (title.length == 0){
                        //     title = "homepage";
                        // }

                        return (
                        <Route exact path={"/docs" + route.path}>
                            <MarkdownPage filepath={route.filepath}/>
                        </Route>
                        )
                    })
                }
                </Switch>
            </Grid>
        </Grid>


      </div>
    </Router>
    </div>
  );
}
