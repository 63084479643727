import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';

// Custom Components
import { DataModelContext } from '../../layouts/Admin';
import ProductTags from './ProductTags';


const styles = {
    paper: {
        padding: "8px",
        boxShadow:"none",
    },

    title:{
        fontSize:"13px",
        fontWeight:"700",
    },

    badge: {
        margin: "0px",
        marginRight: "8px",
        fontSize:"9px",
    },

    chip:{
        marginBottom:"8px",
        marginLeft:"4px"
    },

    chip2:{
        marginLeft:"4px"
    },

    img:{
        maxHeight:"150px",
    },

    center:{
        textAlign:"center",
    }
};
  
const useStyles = makeStyles(styles);

export default function ProductIdentity(props){

    // Styles
    const classes = useStyles();
    
    // Context
    const [dataModel,setDataModel] = React.useContext(DataModelContext);

    // States
    const [data,setData] = React.useState({});
    // const [data,setData] = React.useState({});

    // Functions
    const handleChangeProduct = (name) => {

        // Get data from datamodel
        let productData = dataModel.getProductData(name);
        setData(productData);
    }  


    // Change
    React.useEffect(() => {
      handleChangeProduct(props.productName);
    },[props]);
  

    // Set states
    const img = dataModel.safeGet(data,["meta","image_src"],"");
    const cats = dataModel.safeGet(data,["product_category"],[]);
    const tags = dataModel.safeGet(data,["tags"],[]);

    return (
    <Paper className={classes.paper}>

        {props.productName===null?
        <div>
            <h5 className={classes.title}>Click on a product to learn more</h5>
        </div>
        :
        <div>
          <h5 className={classes.title}>{props.productName}</h5>
          <div style={{textAlign:"center"}}>
              <img src={img} className={classes.img}/>
          </div>
          
          <div>
              <span className={classes.title} style={{marginRight:"4px"}}>Product Category</span>
              <span>
                {
                cats.map(el => (
                    <Chip 
                    size="small" 
                    label={el} 
                    clickable         
                    // onClick={handleClick(product)} 
                    className={classes.chip2} 
                    />

                ))
                }
              </span>
          </div>

          <div>
            <span className={classes.title}>Tags</span>
            {/* <div style={{textAlign:"center"}}>
            {
                tags.map(el => (
                    <Badge className={classes.badge} badgeContent={el.weight} color="secondary">
                        <Chip 
                        size="small" 
                        label={el.tag_name} 
                        clickable         
                        // onClick={handleClick(product)} 
                        className={classes.chip} 
                        />
                    </Badge>

                ))
            }
            </div> */}
            <ProductTags productName={props.productName}/>
          </div>

        </div>
        }


    </Paper>
    )

}