/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

// core components
import Admin from "layouts/Admin.js";
import Login from 'views/Auth/Login';

import "assets/css/material-dashboard-react.css?v=1.8.0";

const hist = createBrowserHistory();

export const UserAuthContext = React.createContext(false);
export const UserDataContext = React.createContext({});

function App(props){

  // States
  const [userAuth, setUserAuth] = React.useState(false);
  const [userData, setUserData] = React.useState({});

  // Temp fix for easier dev
  let adminUserData = {
    "username" : "admin",
    "clean_username" : "Ekimetrics",
    "password" : "ekimetrics",
    "image_background" : "/img/model_oap.png",
    "allowed_datamodels" : null,
    'available_datamodels': ["Biotherm-DMI/Asia","Biotherm-DMI/Europe","Lancome-DMI",'OAP-DMI','OAP-Germany','OAP-China/TMall','OAP-China','OAP-France','Kiehls-DMI'],
    'available_project_names':['loreal_skindr'],
    'available_projects_datamodels':{'loreal_skindr':["Biotherm-DMI/Asia","Biotherm-DMI/Europe","Lancome-DMI",'OAP-DMI','OAP-Germany','OAP-China/TMall','OAP-China','OAP-France','Kiehls-DMI'], 'lorealparis_hairdiag':[]}
    
  }

  let local = (window.location["href"].includes("localhost"))
  console.log("Local ",local);

  // Change
  React.useEffect(() => {
    if (local){
      setUserData(adminUserData);
      setUserAuth(true);
    }
  },[]);



  return (
    <UserAuthContext.Provider value = {[userAuth,setUserAuth]}>
      <UserDataContext.Provider value = {[userData,setUserData]}>
        <div>
          {
            userAuth ? <Admin/> : <Login/>
          }
        </div>
      </UserDataContext.Provider>
    </UserAuthContext.Provider>
  )

}



// class App extends React.Component {

//   constructor(props) {
//     super(props);
//     this.state = {}
//     if (true){ // process.env.REACT_APP_USERNAME === 'local'

//       // Temp fix for easier dev
//       let adminUserData = {
//         "username" : "admin",
//         "clean_username" : "Ekimetrics",
//         "password" : "ekimetrics",
//         "image_background" : "/img/model_oap.png",
//         "allowed_datamodels" : null,
//         'available_datamodels': ['OAP-DMI','OAP-Germany','OAP-China','OAP-France','Kiehls-DMI','Lancome-DMI',"Biotherm-DMI"],
//       }

      
//       let initialState = {isAuthenticated:true,userData:adminUserData};
//       this.state = initialState;

//       // const ThemeContext = React.createContext(this.state);

//   } else {
//       let initialState = {isAuthenticated:false}//process.env.REACT_APP_USERNAME === 'admin' ? true : false};
//       this.state = localStorage.getItem("appState") ? JSON.parse(localStorage.getItem("appState")) : initialState;
//     }
//   }



//   componentWillUnmount() {
//     // Remember state for the next mount
//     localStorage.setItem('appState', JSON.stringify(this.state));
//   }

//   render() {
//       console.log(this.state)
//       return (
//         <div>
//           {
//             this.state.isAuthenticated ? <Admin userData={this.state.userData} logout={this.logout}/> : <Login auth={this.authenticate}/>
//           }
//         </div>
//     )
//   }
// }




ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/" component={App} />
      <Redirect from="/" to="/prescription" />
    </Switch>
  </Router>,
  document.getElementById("root")
);
