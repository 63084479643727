import React from "react";
import Chip from '@material-ui/core/Chip';

export default class DataModel{
  
    // Constructor
    constructor(props){

      this.data = props.data;

      if (!(this.isEmpty())){
        this.brand = this.data.meta.brand;
        this.country = this.data.meta.country;
      }
    }

    isEmpty(){
        return Object.keys(this.data) == 0;
    }

    // Helper function to get environment full name from properties
    buildEnvironmentParams(){
      return `brand=${this.brand}&country=${this.country}`
    }

    // Helper function to get keys from a nested dict or JSON
    safeGet = (dictData,keys,defaultValue) => {
        let value;
        value = dictData;
        try {
            for (var i = 0; i < keys.length; i++) {
                if (keys[i] in value){
                  value = value[keys[i]];
                } else {
                  return defaultValue;
                }
              }
            return value;
        } catch (error) {
            console.log(error);
            return defaultValue;
        }

      }


    getImg = (productName) => {
        let productData = this.getProductData(productName);
        const img = this.safeGet(productData,["meta","image_src"],"");
        return img;
    }
    
    // Import Data from MongoDB directly
    // Can be used to avoid calling 
    fromMongo(){
    }

    // Get questions values
    getQuestionsValues(){
        if (!this.isEmpty()){

            let questions = [];
            this.data.questions.forEach(el => {

                // Get question data
                let question_data = {
                    question_name:el.question_name,
                    values:el.values.map(value=>(value.input_name))
                };

                // Add only if more than one option
                if (question_data.values.length > 1){
                    questions.push(question_data)
                }

                // Add ranges
                let ranges = el.range;
                if (ranges !== null){

                    // Get question data
                    let question_data = {
                        question_name:el.question_name+" range",
                        values:ranges,
                    };
                    questions.push(question_data);
                }

            })
            return questions;
        } else {
            return [];
        }
    }

    // Get products data
    getProductData(product_name){
        if (!this.isEmpty()){
            let product = this.data.products.find(el => el.product_name === product_name);
            return product;
        } else {
            return {};
        }
    }

    // Get all products data in a Table
    // Returns: a list of list with elements:
    //  - product name
    //  - a list of Chips (tags) with product categories
    getProductsDataForTable(){
        let products = this.data.products.map(el => {
            return [
                el.product_name,
                el.product_category.map(cat=>(<Chip clickable size="small" label={cat} style={{marginLeft:4}} />))
            ];
        })
        return products;
    }

    // Get Products by Category
    // Input: One product Category
    // Returns: a list of product names for this category
    getProductsbyCategory(category){
        return (this.data.products
            .filter(product => product.product_category.includes(category))
            .map(el => el.product_name)
        );
    }

    // Get products by routine step
    // Returns: a dict of lists with:
    //  - key = routine_step_name
    //  - value = list of products for this routine step
    getProductsByRoutineSteps(){

        if (!this.isEmpty()){
            let steps = [];
            this.data.routine_steps.forEach(k => {
                let step_data = {
                    routine_step:k.routine_step,
                    routine_step_name:k.routine_step_name
                }
                let products = [];
                k.product_category.forEach(cat => {
                    let newProducts = this.getProductsbyCategory(cat);
                    products = products.concat(newProducts);
                })
                step_data["products"] = products;
                steps.push(step_data);
                steps[k.routine_step_name] = products;
            })
            steps = steps.sort((a,b)=>a.routine_step.localeCompare(b.routine_step));
            return steps;
        } else {
            return [];
        }

    }
  }