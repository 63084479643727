import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import cogoToast from 'cogo-toast';
import StyledButton from './StyledButton';

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";


const projects = [{'value': 'loreal_skindr', 'label': 'SkinDr'}, {'value': 'lorealparis_hairdiag', 'label': "L'Oréal Paris Hairdiag"}]

const styles = {
    root:{
    },
    textField:{
        marginRight:16,
        marginBottom:16,
    },
    content:{
        fontWeight:300,
    }

};

const useStyles = makeStyles(styles);





export default function CreateNewUser(props) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [formData, setFormData] = React.useState({});

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const title = "Create new account";

  const [project, setProjectCode] = React.useState("");
  const handleChangeProject = event => {
    setProjectCode(event.target.value);
    handleChange(event);
  };

  const handleChange = (event) => {
      // pluralsight.com/guides/handling-multiple-inputs-with-single-onchange-handler-react
      const value = event.target.value;
      setFormData({
        ...formData,
        [event.target.name]: value
      });
  }

  const handleCreate = () => {

    // Prepare API options and config
    var options = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Request-Headers': 'Content-Type, Authorization'
      },
    }

    options["body"] = JSON.stringify(formData);
    // API HTTP request
    fetch(process.env.REACT_APP_API_URL + 'app/config/account',options)
    .then(response => response.json())
    .then(data => {

        if (data["error"] == false){
            cogoToast.success(data["message"], { position: 'bottom-left'});
        } else {
            cogoToast.error(data["message"], { position: 'bottom-left'});
        }
        handleClose();
    })
    .catch(function(error) {
        cogoToast.error("Error, see console for more info", { position: 'bottom-left'});
        console.log('Il y a eu un problème avec l\'opération fetch: ' + error.message);
        handleClose();

    });


  }


  return (
      <span>

        <Card>
          <CardBody>
            <DialogContent>
              <DialogContentText className={classes.content}>
                  Create a new user account in this application.
                  <ul>
                      <li>Change the default password if needed ("skindr55PC!" by default)</li>
                      <li>For now, it's not possible to remove or edit a user from the app, ask Ekimetrics to do so</li>
                      <li>To give the user access to all localizations of a brand, keep the localization empty.</li>
                      <li>To give the user access to several localization, separate them with a comma (,). Example : can-en,can-fr</li>
                  </ul>
    
              </DialogContentText>
              <form noValidate autoComplete="off">
              <TextField
                className={classes.textField} 
                name="username"
                label="username"
                required
                variant="outlined"
                onChange={handleChange}
                helperText="ex : biotherm-can"
              />
              <TextField
                className={classes.textField}
                name="password"
                label="password"
                variant="outlined"
                onChange={handleChange}
                helperText="skindr55PC! by default"
              />
              <TextField 
                className={classes.textField} 
                name="project_name" 
                label="project" 
                select 
                variant="outlined" 
                onChange={handleChangeProject}
                helperText="Please Select your project"
              >
                {projects.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
              <TextField
                className={classes.textField}
                name="brand"
                label="brand"
                required
                variant="outlined"
                onChange={handleChange}
                helperText="ex : biotherm"
              />
              <TextField
                className={classes.textField}
                name="localization"
                label="localization"
                variant="outlined"
                onChange={handleChange}
                helperText="ex : can-en,can-fr"
              />
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCreate} color="primary">
                  Create
              </Button>
            </DialogActions>

          </CardBody>
        </Card>
      </span>
  );
}
