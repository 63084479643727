import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';


// React Admin
// https://marmelab.com/react-admin/Readme.html
import { Admin, Resource } from 'react-admin';
import { Layout } from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';
import { PostList, PostEdit, PostCreate, PostIcon } from './posts';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';



const styles = {
    root:{
        paddingLeft:32,
        paddingRight:32,
        paddingTop:16,
        paddingBottom:16,
        marginBottom:32,
    },
    title:{
        fontSize:16,
        fontWeight:700,
    }
};

const useStyles = makeStyles(styles);

const noAppBar = props => (
    <AppBar {...props} style={{display:"none"}}>
    </AppBar>
)
const MyLayout = (props) => <Layout {...props} appBar={noAppBar} />;



export default function AdminView() {
  
  const classes = useStyles();


  return (
    <Admin layout={MyLayout} dataProvider={simpleRestProvider('http://localhost:5000/admin')}>
        <Resource name="users" list={PostList} icon={PostIcon}/>
        {/* <Resource name="users" list={PostList} edit={PostEdit} create={PostCreate} icon={PostIcon}/> */}
    </Admin>
  );
}
