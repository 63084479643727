
let exampleDataModel;
export default exampleDataModel = {
    "products" : [ 
        {
            "product_name" : "ABSOLUE APC EMULSION",
            "business_priority" : 1,
            "catalogue" : [ 
                "2019"
            ],
            "product_category" : [ 
                "Emulsion"
            ],
            "tags" : [ 
                {
                    "tag_name" : "Combination",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Normal",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Dry",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Sensitive",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "0-34",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "35-54",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "55+",
                    "tag_category" : "age",
                    "weight" : 1
                }
            ],
            "meta" : {
                "url_link" : "https://www.lancome.com.sg/product/blanc-expert-beautiful-skin-tone-brightening-emulsion/",
                "image_src" : null
            }
        }, 
        {
            "product_name" : "ABSOLUE APC ROSE PETAL LOTION",
            "business_priority" : 1,
            "catalogue" : [ 
                "2019"
            ],
            "product_category" : [ 
                "Lotion"
            ],
            "tags" : [ 
                {
                    "tag_name" : "Combination",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Normal",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Dry",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Sensitive",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "0-34",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "35-54",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "55+",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "Lack of radiance",
                    "tag_category" : "concerns",
                    "weight" : 1.0
                }
            ],
            "meta" : {
                "url_link" : "https://www.lancome.com.sg/zh/product/absolue-precious-cells-revitalising-rose-lotion/",
                "image_src" : null
            }
        }, 
        {
            "product_name" : "ABSOLUE APC UV",
            "business_priority" : 1,
            "catalogue" : [ 
                "2019"
            ],
            "product_category" : [ 
                "SPF"
            ],
            "tags" : [ 
                {
                    "tag_name" : "Combination",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Normal",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Dry",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Sensitive",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "0-34",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "35-54",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "55+",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "Lack of firmness",
                    "tag_category" : "concerns",
                    "weight" : 1.0
                }
            ],
            "meta" : {
                "url_link" : "https://www.lancome.com.sg/product/absolue-uv/",
                "image_src" : null
            }
        }, 
        {
            "product_name" : "ABSOLUE EYE CREAM",
            "business_priority" : 1,
            "catalogue" : [ 
                "2019"
            ],
            "product_category" : [ 
                "Eye Care"
            ],
            "tags" : [ 
                {
                    "tag_name" : "Wrinkles",
                    "tag_category" : "concerns",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Under-eye wrinkles",
                    "tag_category" : "concerns",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Combination",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Normal",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Dry",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Sensitive",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "0-34",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "35-54",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "55+",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "Lack of radiance",
                    "tag_category" : "concerns",
                    "weight" : 1.0
                }
            ],
            "meta" : {
                "url_link" : "https://www.lancome.co.uk/skincare/by-product-category/eye-lip-care/revitalising-eye-cream/A01495-LAC.html",
                "image_src" : null
            }
        }, 
        {
            "product_name" : "ABSOLUE EYE SERUM",
            "business_priority" : 1,
            "catalogue" : [ 
                "2019"
            ],
            "product_category" : [ 
                "Eye Care"
            ],
            "tags" : [ 
                {
                    "tag_name" : "Under-eye wrinkles",
                    "tag_category" : "concerns",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Combination",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Normal",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Dry",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Sensitive",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "0-34",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "35-54",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "55+",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "Lack of radiance",
                    "tag_category" : "concerns",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Large Pores",
                    "tag_category" : "concerns",
                    "weight" : 1.0
                }
            ],
            "meta" : {
                "url_link" : "https://www.lancome.co.uk/skincare/by-product-category/eye-serum/absolue-eye-serum/A01496-LAC.html",
                "image_src" : null
            }
        }, 
        {
            "product_name" : "ABSOLUE LOTION ROSE 80",
            "business_priority" : 1,
            "catalogue" : [ 
                "2019"
            ],
            "product_category" : [ 
                "Lotion"
            ],
            "tags" : [ 
                {
                    "tag_name" : "Combination",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Normal",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Dry",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Sensitive",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "0-34",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "35-54",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "55+",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "Lack of radiance",
                    "tag_category" : "concerns",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Large Pores",
                    "tag_category" : "concerns",
                    "weight" : 1.0
                }
            ],
            "meta" : {
                "url_link" : "https://www.lancome.com.sg/zh/product/absolue-rose-80-lotion/",
                "image_src" : null
            }
        }, 
        {
            "product_name" : "ABSOLUE OLEO-SERUM",
            "business_priority" : 1,
            "catalogue" : [ 
                "2019"
            ],
            "product_category" : [ 
                "Serum"
            ],
            "tags" : [ 
                {
                    "tag_name" : "Combination",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Normal",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Dry",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Sensitive",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "0-34",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "35-54",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "55+",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "Lack of radiance",
                    "tag_category" : "concerns",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Lack of firmness",
                    "tag_category" : "concerns",
                    "weight" : 1.0
                }
            ],
            "meta" : {
                "url_link" : "https://www.lancome.co.uk/skincare/by-product-category/face-serum/absolue-oleo-serum/A01494-LAC.html",
                "image_src" : null
            }
        }, 
        {
            "product_name" : "ABSOLUE RICH CREAM",
            "business_priority" : 1,
            "catalogue" : [ 
                "2019"
            ],
            "product_category" : [ 
                "Night Care", 
                "Day Care"
            ],
            "tags" : [ 
                {
                    "tag_name" : "Combination",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Normal",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Dry",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Sensitive",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "0-34",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "35-54",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "55+",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "Lack of radiance",
                    "tag_category" : "concerns",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Large Pores",
                    "tag_category" : "concerns",
                    "weight" : 1.0
                }
            ],
            "meta" : {
                "url_link" : "https://www.lancome.co.uk/skincare/by-product-category/moisturisers/rich-cream/A01427-LAC.html",
                "image_src" : null
            }
        }, 
        {
            "product_name" : "ABSOLUE SOFT CREAM",
            "business_priority" : 1,
            "catalogue" : [ 
                "2019"
            ],
            "product_category" : [ 
                "Day Care", 
                "Night Care"
            ],
            "tags" : [ 
                {
                    "tag_name" : "Combination",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Normal",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Dry",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Sensitive",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "0-34",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "35-54",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "55+",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "Lack of radiance",
                    "tag_category" : "concerns",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Lack of firmness",
                    "tag_category" : "concerns",
                    "weight" : 1.0
                }
            ],
            "meta" : {
                "url_link" : "https://www.lancome.co.uk/skincare/by-range/absolue/absolue-soft-cream/A01423-LAC.html",
                "image_src" : null
            }
        }, 
        {
            "product_name" : "ABSOLUE WHITE AURA FRESH CREAM",
            "business_priority" : 1,
            "catalogue" : [ 
                "2019"
            ],
            "product_category" : [ 
                "Day Care", 
                "Night Care"
            ],
            "tags" : [ 
                {
                    "tag_name" : "Combination",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Normal",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Dry",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Sensitive",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "0-34",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "35-54",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "55+",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "Lack of radiance",
                    "tag_category" : "concerns",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Dark pigmentation",
                    "tag_category" : "concerns",
                    "weight" : 1.0
                }
            ],
            "meta" : {
                "url_link" : "https://www.lancome.com.sg/zh/product/absolue-white-aura-fresh-cream/",
                "image_src" : null
            }
        }, 
        {
            "product_name" : "ABSOLUE WHITE AURA SERUM",
            "business_priority" : 1,
            "catalogue" : [ 
                "2019"
            ],
            "product_category" : [ 
                "Serum"
            ],
            "tags" : [ 
                {
                    "tag_name" : "Combination",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Normal",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Dry",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Sensitive",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "0-34",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "35-54",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "55+",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "Lack of radiance",
                    "tag_category" : "concerns",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Dark pigmentation",
                    "tag_category" : "concerns",
                    "weight" : 1.0
                }
            ],
            "meta" : {
                "url_link" : "https://www.lancome.com.sg/zh/product/absolue-white-aura-serum/",
                "image_src" : null
            }
        }, 
        {
            "product_name" : "ADVANCED GENIFIQUE SENSITIVE Dual Concentrate 20ml",
            "business_priority" : 1,
            "catalogue" : [ 
                "2019"
            ],
            "product_category" : [ 
                "Serum"
            ],
            "tags" : [ 
                {
                    "tag_name" : "Combination",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Normal",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Dry",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Sensitive",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "0-34",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "35-54",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "55+",
                    "tag_category" : "age",
                    "weight" : 1
                }
            ],
            "meta" : {
                "url_link" : "https://www.lancome.co.uk/skincare/by-product-category/face-serum/advanced-genifique-sensitive/A00798-LAC.html",
                "image_src" : null
            }
        }, 
        {
            "product_name" : "ADVANCED GENIFIQUE YEUX Youth Activating Smoothing Eye Cream 15ml",
            "business_priority" : 1,
            "catalogue" : [ 
                "2019"
            ],
            "product_category" : [ 
                "Eye Care"
            ],
            "tags" : [ 
                {
                    "tag_name" : "Under-eye wrinkles",
                    "tag_category" : "concerns",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Combination",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Normal",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Dry",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Sensitive",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "0-34",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "35-54",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "55+",
                    "tag_category" : "age",
                    "weight" : 1
                }
            ],
            "meta" : {
                "url_link" : "https://www.lancome.co.uk/skincare/by-skin-concern/anti-ageing/advanced-genifique-eye-cream/A01034-LAC.html",
                "image_src" : null
            }
        }, 
        {
            "product_name" : "ADVANCED GENIFIQUE YEUX Youth Activating Smoothing Eye Cream 30ml",
            "business_priority" : 1,
            "catalogue" : [ 
                "2019"
            ],
            "product_category" : [ 
                "Eye Care"
            ],
            "tags" : [ 
                {
                    "tag_name" : "Under-eye wrinkles",
                    "tag_category" : "concerns",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Combination",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Normal",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Dry",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Sensitive",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "0-34",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "35-54",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "55+",
                    "tag_category" : "age",
                    "weight" : 1
                }
            ],
            "meta" : {
                "url_link" : "https://www.lancome.co.uk/skincare/by-skin-concern/anti-ageing/advanced-genifique-eye-cream/A01034-LAC.html",
                "image_src" : null
            }
        }, 
        {
            "product_name" : "ADVANCED GENIFIQUE Youth Activating Concentrate 115ml",
            "business_priority" : 1,
            "catalogue" : [ 
                "2019"
            ],
            "product_category" : [ 
                "Serum"
            ],
            "tags" : [ 
                {
                    "tag_name" : "Combination",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Normal",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Dry",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Sensitive",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "0-34",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "35-54",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "55+",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "Lack of radiance",
                    "tag_category" : "concerns",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Lack of firmness",
                    "tag_category" : "concerns",
                    "weight" : 1.0
                }
            ],
            "meta" : {
                "url_link" : "https://www.lancome.co.uk/skincare/by-product-category/face-serum/advanced-genifique-serum/26901c-LAC.html",
                "image_src" : null
            }
        }, 
        {
            "product_name" : "BI-FACIL MUR 200ML",
            "business_priority" : 1,
            "catalogue" : [ 
                "2019"
            ],
            "product_category" : [ 
                "MU Removal"
            ],
            "tags" : [ 
                {
                    "tag_name" : "Sensitive",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "0-34",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "35-54",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "55+",
                    "tag_category" : "age",
                    "weight" : 1
                }
            ],
            "meta" : {
                "url_link" : "https://www.lancome.com.sg/product/bi-facil/",
                "image_src" : null
            }
        }, 
        {
            "product_name" : "EAU MICELLAIRE CONFORT",
            "business_priority" : 1,
            "catalogue" : [ 
                "2019"
            ],
            "product_category" : [ 
                "MU Removal"
            ],
            "tags" : [ 
                {
                    "tag_name" : "Dry",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Sensitive",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "0-34",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "35-54",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "55+",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "Lack of radiance",
                    "tag_category" : "concerns",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Large Pores",
                    "tag_category" : "concerns",
                    "weight" : 1.0
                }
            ],
            "meta" : {
                "url_link" : "https://www.lancome.co.uk/skincare/by-product-category/cleansers-toners/eau-micellaire-confort/A00934-LAC.html",
                "image_src" : null
            }
        }, 
        {
            "product_name" : "GALATEE CONFORT CLEANSING MILK",
            "business_priority" : 1,
            "catalogue" : [ 
                "2019"
            ],
            "product_category" : [ 
                "Cleansing Milk"
            ],
            "tags" : [ 
                {
                    "tag_name" : "Combination",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Normal",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Dry",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Sensitive",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "0-34",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "35-54",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "55+",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "Large Pores",
                    "tag_category" : "concerns",
                    "weight" : 1.0
                }
            ],
            "meta" : {
                "url_link" : "https://www.lancome.com.sg/product/galatee-confort/",
                "image_src" : null
            }
        }, 
        {
            "product_name" : "GALATEIS DOUCEUR",
            "business_priority" : 1,
            "catalogue" : [ 
                "2019"
            ],
            "product_category" : [ 
                "MU Removal"
            ],
            "tags" : [ 
                {
                    "tag_name" : "Combination",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Normal",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Dry",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Sensitive",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "0-34",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "35-54",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "55+",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "Large Pores",
                    "tag_category" : "concerns",
                    "weight" : 1.0
                }
            ],
            "meta" : {
                "url_link" : "https://www.lancome.com.sg/product/douceur-galateis/",
                "image_src" : "https://www.lancome.com.sg/assets/product-images/douceur_galateis-1563336949-kl.jpg"
            }
        }, 
        {
            "product_name" : "GENIFIQUE CREME Youth Activating Day Cream 50ML",
            "business_priority" : 1,
            "catalogue" : [ 
                "2019"
            ],
            "product_category" : [ 
                "Day Care"
            ],
            "tags" : [ 
                {
                    "tag_name" : "Combination",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Normal",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Dry",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Sensitive",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "0-34",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "35-54",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "55+",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "Lack of radiance",
                    "tag_category" : "concerns",
                    "weight" : 1.0
                }
            ],
            "meta" : {
                "url_link" : "https://www.lancome.co.uk/skincare/by-product-category/moisturisers/genifique-creme/269041-LAC.html",
                "image_src" : null
            }
        }, 
        {
            "product_name" : "GENIFIQUE REPAIR Youth Activating Night Cream 50ML",
            "business_priority" : 1,
            "catalogue" : [ 
                "2019"
            ],
            "product_category" : [ 
                "Night Care"
            ],
            "tags" : [ 
                {
                    "tag_name" : "Wrinkles",
                    "tag_category" : "concerns",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Combination",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Normal",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Dry",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Sensitive",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "0-34",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "35-54",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "55+",
                    "tag_category" : "age",
                    "weight" : 1
                }
            ],
            "meta" : {
                "url_link" : "https://www.lancome.co.uk/skincare/by-product-category/moisturisers/genifique-repair/269051-LAC.html",
                "image_src" : null
            }
        }, 
        {
            "product_name" : "GENIFIQUE YEUX LIGHT PEARL Youth Activating Eye & Lash Concentrate 20ml",
            "business_priority" : 1,
            "catalogue" : [ 
                "2019"
            ],
            "product_category" : [ 
                "Eye Care"
            ],
            "tags" : [ 
                {
                    "tag_name" : "Under-eye wrinkles",
                    "tag_category" : "concerns",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Combination",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Normal",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Dry",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Sensitive",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "0-34",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "35-54",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "55+",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "Large Pores",
                    "tag_category" : "concerns",
                    "weight" : 1.0
                }
            ],
            "meta" : {
                "url_link" : "https://www.lancome.co.uk/skincare/by-product-category/eye-serum/advanced-genifique-light-pearl/A01537-LAC.html",
                "image_src" : null
            }
        }, 
        {
            "product_name" : "HYDRA ZEN EXTREME GEL",
            "business_priority" : 1,
            "catalogue" : [ 
                "2019"
            ],
            "product_category" : [ 
                "Moisturizer"
            ],
            "tags" : [ 
                {
                    "tag_name" : "Wrinkles",
                    "tag_category" : "concerns",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Combination",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Normal",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Dry",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Sensitive",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "0-34",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "35-54",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "55+",
                    "tag_category" : "age",
                    "weight" : 1
                }
            ],
            "meta" : {
                "url_link" : "https://www.lancome.com.sg/product/hydra-zen-cream-gel/",
                "image_src" : null
            }
        }, 
        {
            "product_name" : "HYDRAZEN CREME DE JOUR",
            "business_priority" : 1,
            "catalogue" : [ 
                "2019"
            ],
            "product_category" : [ 
                "Day Care"
            ],
            "tags" : [ 
                {
                    "tag_name" : "Normal",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Dry",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "0-34",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "35-54",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "55+",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "Lack of firmness",
                    "tag_category" : "concerns",
                    "weight" : 1.0
                }
            ],
            "meta" : {
                "url_link" : "https://www.lancome.com.sg/product/hydra-zen-day-cream/",
                "image_src" : null
            }
        }, 
        {
            "product_name" : "HYDRAZEN CREME YEUX",
            "business_priority" : 1,
            "catalogue" : [ 
                "2019"
            ],
            "product_category" : [ 
                "Eye Care"
            ],
            "tags" : [ 
                {
                    "tag_name" : "Under-eye wrinkles",
                    "tag_category" : "concerns",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Combination",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Normal",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Dry",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Sensitive",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "0-34",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "35-54",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "55+",
                    "tag_category" : "age",
                    "weight" : 1
                }
            ],
            "meta" : {
                "url_link" : "https://www.lancome.co.uk/skincare/by-product-category/eye-lip-care/hydra-zen-neurocalmtm-eye/085193-LAC.html",
                "image_src" : null
            }
        }, 
        {
            "product_name" : "HYDRAZEN NUIT",
            "business_priority" : 1,
            "catalogue" : [ 
                "2019"
            ],
            "product_category" : [ 
                "Night Care"
            ],
            "tags" : [ 
                {
                    "tag_name" : "Combination",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Normal",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Dry",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Sensitive",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "0-34",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "35-54",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "55+",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "Lack of firmness",
                    "tag_category" : "concerns",
                    "weight" : 1.0
                }
            ],
            "meta" : {
                "url_link" : "https://www.lancome.com.sg/product/hydra-zen-night-masque-night-cream/",
                "image_src" : null
            }
        }, 
        {
            "product_name" : "MOUSSE ECLAT",
            "business_priority" : 1,
            "catalogue" : [ 
                "2019"
            ],
            "product_category" : [ 
                "Cleansing Foam"
            ],
            "tags" : [ 
                {
                    "tag_name" : "Combination",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Normal",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Dry",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Sensitive",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "0-34",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "35-54",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "55+",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "Large Pores",
                    "tag_category" : "concerns",
                    "weight" : 1.0
                }
            ],
            "meta" : {
                "url_link" : "https://www.lancome.com.sg/product/mousse-radiance/",
                "image_src" : "https://www.lancome.com.sg/assets/product-images/mousse_radiance-1563356281-kl.jpg"
            }
        }, 
        {
            "product_name" : "RENERGIE MULTI-LIFT Lifting and Firming Night Cream 75ML",
            "business_priority" : 1,
            "catalogue" : [ 
                "2019"
            ],
            "product_category" : [ 
                "Night Care"
            ],
            "tags" : [ 
                {
                    "tag_name" : "Wrinkles",
                    "tag_category" : "concerns",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Combination",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Normal",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Dry",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Sensitive",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "0-34",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "35-54",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "55+",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "Lack of radiance",
                    "tag_category" : "concerns",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Lack of firmness",
                    "tag_category" : "concerns",
                    "weight" : 1.0
                }
            ],
            "meta" : {
                "url_link" : "https://www.lancome.co.uk/skincare/by-product-category/moisturisers/renergie-multi-lift-day-cream/09043b-LAC.html",
                "image_src" : null
            }
        }, 
        {
            "product_name" : "RENERGIE MULTI-LIFT Lifting, Firming, Anti-wrinkle Cream 50ML",
            "business_priority" : 1,
            "catalogue" : [ 
                "2019"
            ],
            "product_category" : [ 
                "Day Care", 
                "Night Care"
            ],
            "tags" : [ 
                {
                    "tag_name" : "Wrinkles",
                    "tag_category" : "concerns",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Combination",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Normal",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Dry",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Sensitive",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "0-34",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "35-54",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "55+",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "Lack of radiance",
                    "tag_category" : "concerns",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Lack of firmness",
                    "tag_category" : "concerns",
                    "weight" : 1.0
                }
            ],
            "meta" : {
                "url_link" : "https://www.lancome.co.uk/skincare/by-product-category/moisturisers/renergie-multi-lift-day-cream/09043b-LAC.html",
                "image_src" : null
            }
        }, 
        {
            "product_name" : "RENERGIE MULTI-LIFT Lifting, Firming, Anti-wrinkle Cream light 50ML",
            "business_priority" : 1,
            "catalogue" : [ 
                "2019"
            ],
            "product_category" : [ 
                "Day Care", 
                "Night Care"
            ],
            "tags" : [ 
                {
                    "tag_name" : "Wrinkles",
                    "tag_category" : "concerns",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Combination",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Normal",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Dry",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Sensitive",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "0-34",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "35-54",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "55+",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "Lack of radiance",
                    "tag_category" : "concerns",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Lack of firmness",
                    "tag_category" : "concerns",
                    "weight" : 1.0
                }
            ],
            "meta" : {
                "url_link" : "https://www.lancome.co.uk/skincare/by-product-category/moisturisers/renergie-multi-lift-day-cream/09043b-LAC.html",
                "image_src" : null
            }
        }, 
        {
            "product_name" : "RENERGIE MULTI-LIFT Lifting, Firming, Anti-wrinkle Cream rich 50ML",
            "business_priority" : 1,
            "catalogue" : [ 
                "2019"
            ],
            "product_category" : [ 
                "Day Care", 
                "Night Care"
            ],
            "tags" : [ 
                {
                    "tag_name" : "Wrinkles",
                    "tag_category" : "concerns",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Combination",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Normal",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Dry",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Sensitive",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "0-34",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "35-54",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "55+",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "Lack of radiance",
                    "tag_category" : "concerns",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Lack of firmness",
                    "tag_category" : "concerns",
                    "weight" : 1.0
                }
            ],
            "meta" : {
                "url_link" : "https://www.lancome.co.uk/skincare/by-product-category/moisturisers/renergie-multi-lift-day-cream/09043b-LAC.html",
                "image_src" : null
            }
        }, 
        {
            "product_name" : "RENERGIE MULTI-LIFT Lifting, Firming, Anti-wrinkle Eye Cream 15ML",
            "business_priority" : 1,
            "catalogue" : [ 
                "2019"
            ],
            "product_category" : [ 
                "Eye Care"
            ],
            "tags" : [ 
                {
                    "tag_name" : "Wrinkles",
                    "tag_category" : "concerns",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Combination",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Normal",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Dry",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Sensitive",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "0-34",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "35-54",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "55+",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "Lack of radiance",
                    "tag_category" : "concerns",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Lack of firmness",
                    "tag_category" : "concerns",
                    "weight" : 1.0
                }
            ],
            "meta" : {
                "url_link" : "https://www.lancome.co.uk/skincare/by-product-category/moisturisers/renergie-multi-lift-day-cream/09043b-LAC.html",
                "image_src" : null
            }
        }, 
        {
            "product_name" : "RENERGIE MULTI-LIFT ULTRA Anti-wrinkle, Firming, Tone Evenness Cream 50ML",
            "business_priority" : 1,
            "catalogue" : [ 
                "2019"
            ],
            "product_category" : [ 
                "Day Care", 
                "Night Care"
            ],
            "tags" : [ 
                {
                    "tag_name" : "Wrinkles",
                    "tag_category" : "concerns",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Combination",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Normal",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Dry",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Sensitive",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "0-34",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "35-54",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "55+",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "Lack of firmness",
                    "tag_category" : "concerns",
                    "weight" : 1.0
                }
            ],
            "meta" : {
                "url_link" : "https://www.lancome.co.uk/skincare/by-skin-concern/anti-ageing/renergie-multi-lift-ultra-full-spectrum-cream/A01679-LAC.html",
                "image_src" : null
            }
        }, 
        {
            "product_name" : "RENERGIE MULTI-LIFT ULTRA SPF Anti-wrinkle, Firming, Tone Evenness Cream 50ML",
            "business_priority" : 1,
            "catalogue" : [ 
                "2019"
            ],
            "product_category" : [ 
                "Day Care", 
                "Night Care"
            ],
            "tags" : [ 
                {
                    "tag_name" : "Wrinkles",
                    "tag_category" : "concerns",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Under-eye wrinkles",
                    "tag_category" : "concerns",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Combination",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Normal",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Dry",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Sensitive",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "0-34",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "35-54",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "55+",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "Lack of radiance",
                    "tag_category" : "concerns",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Lack of firmness",
                    "tag_category" : "concerns",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Dark pigmentation",
                    "tag_category" : "concerns",
                    "weight" : 1.0
                }
            ],
            "meta" : {
                "url_link" : "https://www.lancome.co.uk/skincare/by-product-category/moisturisers/renergie-multi-lift-ultra-fluid/A00353-LAC.html",
                "image_src" : null
            }
        }, 
        {
            "product_name" : "TONIQUE CONFORT ",
            "business_priority" : 1,
            "catalogue" : [ 
                "2019"
            ],
            "product_category" : [ 
                "Toner"
            ],
            "tags" : [ 
                {
                    "tag_name" : "Combination",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Normal",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Dry",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Sensitive",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "0-34",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "35-54",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "55+",
                    "tag_category" : "age",
                    "weight" : 1
                }
            ],
            "meta" : {
                "url_link" : "https://www.lancome.com.sg/product/tonique-confort/",
                "image_src" : null
            }
        }, 
        {
            "product_name" : "TONIQUE DOUCEUR",
            "business_priority" : 1,
            "catalogue" : [ 
                "2019"
            ],
            "product_category" : [ 
                "Lotion"
            ],
            "tags" : [ 
                {
                    "tag_name" : "Combination",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Normal",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Dry",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "Sensitive",
                    "tag_category" : "skin_type",
                    "weight" : 1.0
                }, 
                {
                    "tag_name" : "0-34",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "35-54",
                    "tag_category" : "age",
                    "weight" : 1
                }, 
                {
                    "tag_name" : "55+",
                    "tag_category" : "age",
                    "weight" : 1
                }
            ],
            "meta" : {
                "url_link" : "https://www.lancome.com.sg/product/tonique-douceur/",
                "image_src" : "https://www.lancome.com.sg/assets/product-images/tonique_douceur-1563336993-kl.jpg"
            }
        }
    ],
    "questions" : [ 
        {
            "question_name" : "age",
            "question_category" : "age",
            "criteria" : "strict",
            "weight" : 1,
            "range" : null,
            "values" : [ 
                {
                    "input_name" : "35-55",
                    "tags" : [ 
                        {
                            "tag_name" : "35-54",
                            "tag_category" : "age",
                            "weight" : 1
                        }
                    ]
                }, 
                {
                    "input_name" : "0-Below 35",
                    "tags" : [ 
                        {
                            "tag_name" : "0-34",
                            "tag_category" : "age",
                            "weight" : 1
                        }
                    ]
                }, 
                {
                    "input_name" : "55+",
                    "tags" : [ 
                        {
                            "tag_name" : "55+",
                            "tag_category" : "age",
                            "weight" : 1
                        }
                    ]
                }
            ]
        }, 
        {
            "question_name" : "priority 1",
            "question_category" : "concerns",
            "criteria" : "scoring",
            "weight" : 1,
            "range" : null,
            "values" : [ 
                {
                    "input_name" : "Even Tone",
                    "tags" : [ 
                        {
                            "tag_name" : "Dark pigmentation",
                            "tag_category" : "concerns",
                            "weight" : 1
                        }
                    ]
                }, 
                {
                    "input_name" : "Firmness",
                    "tags" : [ 
                        {
                            "tag_name" : "Lack of firmness",
                            "tag_category" : "concerns",
                            "weight" : 1
                        }
                    ]
                }, 
                {
                    "input_name" : "Pore Quality",
                    "tags" : [ 
                        {
                            "tag_name" : "Large Pores",
                            "tag_category" : "concerns",
                            "weight" : 1
                        }
                    ]
                }, 
                {
                    "input_name" : "Wrinkle Resilience",
                    "tags" : [ 
                        {
                            "tag_name" : "Wrinkles",
                            "tag_category" : "concerns",
                            "weight" : 1
                        }, 
                        {
                            "tag_name" : "Fine lines",
                            "tag_category" : "concerns",
                            "weight" : 1
                        }
                    ]
                }, 
                {
                    "input_name" : "Radiance",
                    "tags" : [ 
                        {
                            "tag_name" : "Lack of radiance",
                            "tag_category" : "concerns",
                            "weight" : 1
                        }
                    ]
                }
            ]
        }, 
        {
            "question_name" : "priority 2",
            "question_category" : "concerns",
            "criteria" : "scoring",
            "weight" : 1,
            "range" : null,
            "values" : [ 
                {
                    "input_name" : "Even Tone",
                    "tags" : [ 
                        {
                            "tag_name" : "Dark pigmentation",
                            "tag_category" : "concerns",
                            "weight" : 1
                        }
                    ]
                }, 
                {
                    "input_name" : "Firmness",
                    "tags" : [ 
                        {
                            "tag_name" : "Lack of firmness",
                            "tag_category" : "concerns",
                            "weight" : 1
                        }
                    ]
                }, 
                {
                    "input_name" : "Pore Quality",
                    "tags" : [ 
                        {
                            "tag_name" : "Large Pores",
                            "tag_category" : "concerns",
                            "weight" : 1
                        }
                    ]
                }, 
                {
                    "input_name" : "Wrinkle Resilience",
                    "tags" : [ 
                        {
                            "tag_name" : "Wrinkles",
                            "tag_category" : "concerns",
                            "weight" : 1
                        }, 
                        {
                            "tag_name" : "Fine lines",
                            "tag_category" : "concerns",
                            "weight" : 1
                        }
                    ]
                }, 
                {
                    "input_name" : "Radiance",
                    "tags" : [ 
                        {
                            "tag_name" : "Lack of radiance",
                            "tag_category" : "concerns",
                            "weight" : 1
                        }
                    ]
                }
            ]
        }, 
        {
            "question_name" : "skin_type",
            "question_category" : "skin_type",
            "criteria" : "strict",
            "weight" : 1,
            "range" : null,
            "values" : [ 
                {
                    "input_name" : "Sensitive",
                    "tags" : [ 
                        {
                            "tag_name" : "Sensitive",
                            "tag_category" : "skin_type",
                            "weight" : 1
                        }
                    ]
                }, 
                {
                    "input_name" : "Dry",
                    "tags" : [ 
                        {
                            "tag_name" : "Dry",
                            "tag_category" : "skin_type",
                            "weight" : 1
                        }
                    ]
                }, 
                {
                    "input_name" : "Normal",
                    "tags" : [ 
                        {
                            "tag_name" : "Normal",
                            "tag_category" : "skin_type",
                            "weight" : 1
                        }
                    ]
                }, 
                {
                    "input_name" : "Combination",
                    "tags" : [ 
                        {
                            "tag_name" : "Combination",
                            "tag_category" : "skin_type",
                            "weight" : 1
                        }
                    ]
                }
            ]
        }
    ],
    "routine_steps" : [ 
        {
            "routine_step" : "04. Eye Care",
            "routine_step_name" : "Eye Care",
            "mandatory" : 1,
            "routine" : "01. Must Have",
            "product_category" : [ 
                "Eye Care"
            ]
        }, 
        {
            "routine_step" : "01. Cleanser",
            "routine_step_name" : "Cleanser",
            "mandatory" : 1,
            "routine" : "01. Must Have",
            "product_category" : [ 
                "MU Removal", 
                "Cleansing Milk", 
                "Cleansing Foam"
            ]
        }, 
        {
            "routine_step" : "02. Serum",
            "routine_step_name" : "Serum",
            "mandatory" : 1,
            "routine" : "01. Must Have",
            "product_category" : [ 
                "Serum"
            ]
        }, 
        {
            "routine_step" : "03. Day Care",
            "routine_step_name" : "Day Care",
            "mandatory" : 1,
            "routine" : "01. Must Have",
            "product_category" : [ 
                "Day Care"
            ]
        }
    ],
    "meta" : {
        "brand" : "Lancome",
        "country" : "DMI",
        "version" : "0.0",
        "date" : "2019-11-06 17:24:14"
    }
};