import React from "react";
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";

// @material-ui/icons
import Person from "@material-ui/icons/Person";
import LoopIcon from '@material-ui/icons/Loop';

// core components
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";

// SkinDr components & context
import { EnvironmentContext } from '../../layouts/Admin';
import { ProjectContext } from '../../layouts/Admin';
import { UserAuthContext } from '../../index';
import EnvButton from './EnvButton';
import ProjectButton from './ProjectButton';
import RefreshButton from './RefreshButton';




const useStyles = makeStyles(styles);

export default function AdminNavbarLinks(props) {
  const classes = useStyles();

  // Context
  const env = React.useContext(EnvironmentContext);
  const project = React.useContext(ProjectContext);
  const [userAuth,setUserAuth] = React.useContext(UserAuthContext);

  // State
  const [openProfile, setOpenProfile] = React.useState(null);

  const handleClickProfile = event => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };

  const handleLogout = () => {
    handleCloseProfile();
    setUserAuth(false);
  }

  const handleCloseProfile = () => {
    setOpenProfile(null);

  };


  return (
    <div>
      <div className={classes.manager}>
        <Hidden mdUp implementation="css">
          <Button
            color={window.innerWidth > 959 ? "black" : "white"}
            justIcon={window.innerWidth > 959}
            simple={!(window.innerWidth > 959)}
            aria-haspopup="true"
            className={classes.buttonLink}
          >
              <p className={classes.linkText}>{project}</p>
              <p className={classes.linkText}>{env}</p>
          </Button>
        </Hidden>
      </div>

      <div className={classes.manager}>
        <ProjectButton userData={props.userData}/>
      </div>

      <div className={classes.manager}>
        <EnvButton userData={props.userData}/>
      </div>

      <div className={classes.manager}>
        <RefreshButton userData={props.userData}/>
      </div>
      <div className={classes.manager}>
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openProfile ? "profile-menu-list-grow" : null}
          aria-haspopup="true"
          onClick={handleClickProfile}
          className={classes.buttonLink}
        >
          <Person className={classes.icons} />
          <Hidden mdUp implementation="css">
            <p className={classes.linkText}>Profile</p>
          </Hidden>
        </Button>
        <Poppers
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openProfile }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom"
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    {/* <MenuItem
                      onClick={handleCloseProfile}
                      className={classes.dropdownItem}
                    >
                      Profile
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseProfile}
                      className={classes.dropdownItem}
                    >
                      Settings
                    </MenuItem> */}
                    {/* <Divider light /> */}
                    <MenuItem
                      onClick={handleLogout}
                      className={classes.dropdownItem}
                    >
                      Logout
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
    </div>
  );
}
