import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components

// Datamodel functionalities
import exampleData from 'datamodel/exampleData';
import DataModel from 'datamodel/datamodel';

// Grid and cards
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

// Custom Components
import ProductsByRoutineSteps from './ProductsByRoutineSteps';
import ProductsTable from './ProductsTable';
import { DataModelContext } from '../../layouts/Admin';
import ProductIdentity from "./ProductIdentity";


const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const useStyles = makeStyles(styles);

export default function ProductsView() {
  const classes = useStyles();

  // States
  const [selectedProduct,setSelectedProduct] = React.useState(null);

  // Context
  const [dataModel,setDataModel] = React.useContext(DataModelContext);

  const handleSelectProduct = (productName) => {
    setSelectedProduct(productName)
  };

  return (
    <GridContainer>
      {/* <ProductsTable data={dataModel.getProductsDataForTable()}/> */}
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>All products</h4>
            <p className={classes.cardCategoryWhite}>
              All possible products classified by routine steps, with radar chart to know product strengths and weaknesses
            </p>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={5}>
                <ProductsByRoutineSteps data={dataModel.getProductsByRoutineSteps()} handleSelectProduct={handleSelectProduct}/>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <ProductIdentity productName={selectedProduct}/>
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
              </GridItem>

            </GridContainer>
          </CardBody>
      </Card>
      {/* <Card>
        <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Product Heatmaps</h4>
            <p className={classes.cardCategoryWhite}>
              Compare products tagging
            </p>
          </CardHeader>
          <CardBody>
          </CardBody>
      </Card> */}
    </GridContainer>
  );
}
