import React from "react";
import { Link , Route, Switch } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import CreateNewUser from './CreateNewUser';
import CreateNewAirtableMapping from "./CreateNewAirtableMapping";
import ExportJson from "./ExportJson";
import { Button, ButtonGroup } from "@material-ui/core";


const styles = {
    root:{
        paddingLeft:32,
        paddingRight:32,
        paddingTop:16,
        paddingBottom:16,
        marginBottom:32,
    },
    title:{
        fontSize:16,
        fontWeight:700,

    }
};

const useStyles = makeStyles(styles);

export default function AdminPanel() {
  const classes = useStyles();

  return (
    <div>
      <div className="buttons">
      <ButtonGroup color="primary" aria-label="outlined primary button group">
        <Link to={`/config/create_new_user`} className="link">
            <Button type="button" variant="contained" color="primary">
                Create New User
            </Button>
        </Link>
        <Link to={`/config/create_new_airtable_mapping`} className="link">
            <Button type="button" variant="contained" color="primary">
                Create New Airtable Mapping
            </Button>
        </Link>
        <Link to={`/config/export_json`} className="link">
            <Button type="button" variant="contained" color="primary">
                Export JSON
            </Button>
        </Link>
        </ButtonGroup>
      </div>
      <div className="tabs">
        <Switch>
          <Route path={`/config/create_new_user`} component={CreateNewUser} />
          <Route path={`/config/create_new_airtable_mapping`} component={CreateNewAirtableMapping} />
          <Route path={`/config/export_json`} component={ExportJson} />
        </Switch>
      </div>
    </div>
  );
}
