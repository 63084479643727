import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Typography from "@material-ui/core/Typography";
import Grid from '@material-ui/core/Grid';
import PrescriptionImg from "assets/img/svgs/artificial_intelligence.svg";
import ProductsImg from "assets/img/svgs/data_arranging.svg";
import AnalysisImg from "assets/img/svgs/analyza_data_and_chart.svg";
import DocumentationImg from "assets/img/svgs/rocket_boy.svg";
// import Link from '@material-ui/core/Link';
import {Link} from "react-router-dom";

function getImage(route){
    if (route === "Prescription"){
        return PrescriptionImg;
    } else if (route === "Products"){
        return ProductsImg;
    } else if (route === "Analysis"){
        return AnalysisImg;
    } else if (route === "Documentation"){
        return DocumentationImg;
    } else {
    }

}

const styles = {

    blockContainer:{
        padding:16,
    },

    block:{
        backgroundColor:"#fff",
        padding:16,
        borderRadius:8,
        boxShadow:"0 15px 10px rgba(0,0,0,0.16)"
    },
    title:{
        textAlign:"center",
        fontSize:"18px",
        fontWeight:700,
        marginBottom:"32px",
    },

    imgcontainer:{
        textAlign:"center",
        marginBottom:"32px",
    },

    content:{
        textAlign:"center",
        fontSize:"14px",
        fontWeight:300,
        height:70,
        margin:"auto",
        verticalAlign:"center",
    },

    img:{
        margin:"auto",
        width: "50%", 
        display: "block",
    },
};

const useStyles = makeStyles(styles);

export default function HomepageBlock(props) {
  const classes = useStyles();
  return (
    <Grid item xs={12} sm={12} md={3} className={classes.blockContainer}>
        <div className={classes.block}>
        <Typography variant="h5" component="h5" className={classes.title} style={props.disable ? {color:"gray"}:{}}>
            {props.title}
        </Typography>
        <Typography className={classes.imgcontainer}>
            <Link to={props.href}>
            <img
                className={classes.img}
                alt="homepage_img"
                src = {getImage(props.title)}
            />
            </Link>
        </Typography>
        <Typography variant="h5" component="h5" className={classes.content} style={props.disable ? {color:"gray"}:{}}>
            {props.description}
        </Typography>
        </div>
    </Grid>
  );
}
