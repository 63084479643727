import React from 'react';
import ProductCard from './ProductCard';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import Slider from './Slider';
import Hidden from "@material-ui/core/Hidden";


const styles = theme => ({
    routine:{
      width:"100%"
      // marginBottom:"32px",
    },

    subroutine:{
      color:"#00add0",
      fontSize: "14px",
    },

    chip:{
      margin:"4px",
      backgroundColor:"#e0e0e0",
      color:"black",
      fontWeight: "500",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    },

    row:{
      marginBottom:"16px",
    }

});

const useStyles = makeStyles(styles);




export default function Routine(props){

  const classes = useStyles();

    // <Box display="flex" justifyContent="center" alignItems="center" className = {classes.routine}>

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      className={classes.row}
    >
      <Grid item xs={12} sm={12} md={2} style={{"textAlign":"center"}}>
        <Chip size="small" label={props.routine} className={classes.chip}/>
      </Grid>
      <Hidden mdUp implementation="css">
        <div style={{marginBottom:"64px"}}></div>
      </Hidden>
      <Grid item xs={12} sm={12} md={10} style={{"textAlign":"center"}}>
        <Slider components={
          props.steps.map(step => {

          if (true){
              // products = products.sort((a,b) => b.score - a.score)
              var recommendation = step["recommendation"];
              var alternatives = step["alternatives"];
              var name = recommendation["product"];
              var score = recommendation["score"];
              var img = recommendation["meta"]["image_src"]; //productData["meta"]["image_src"];
              var tags = {};
              // var tags = props["productsData"][name]["tags"];
          } else {
              name = "";
              img = "";
              score="";
              tags = {};
              alternatives=[];
          }

          return (

              <ProductCard
                  cols={2}
                  header={step["routine_step_name"]}
                  img={img}
                  name={name}
                  desc={""}
                  tags={tags}
                  score={score}
                  alternatives={alternatives}
                  data={step}
              />
          )

          })
      }/>

    {/* </Box> */}
    <Hidden mdUp implementation="css">
      <div style={{marginBottom:"55px"}}></div>
    </Hidden>
    {/* <Grid item xs={12} sm={12} md={0}>
    </Grid> */}
    {/* </Grid> */}
    </Grid>
  </Grid>

  // </div>
)
}

  