import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import cogoToast from 'cogo-toast';
import StyledButton from './StyledButton';
import { OverlayContext } from '../../layouts/Admin';
// for switch button
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import 'handsontable/dist/handsontable.full.css';
import { HotTable } from '@handsontable/react';

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

const styles = {
    root:{
    },
    textField:{
        marginRight:16,
        marginBottom:16,
    },
    content:{
        fontWeight:300,
    }

};

const useStyles = makeStyles(styles);

const brands = [{'value': 'giorgioarmani', 'label': 'Giorgio Armani'},
{'value': 'ateliercologne', 'label': 'Atelier Cologne'},
{'value': 'biolage', 'label': 'Biolage'},
{'value': 'biotherm', 'label': 'Biotherm'},
{'value': 'biotherm-men', 'label': 'Biotherm Men'},
{'value': 'cacharel', 'label': 'Cacharel'},
{'value': 'carita', 'label': 'Carita'},
{'value': 'cerave', 'label': 'CeraVe'},
{'value': 'clarisonic', 'label': 'Clarisonic'},
{'value': 'decleor', 'label': 'Decléor'},
{'value': 'diesel', 'label': 'Diesel'},
{'value': 'essie', 'label': 'Essie'},
{'value': 'garnier', 'label': 'Garnier'},
{'value': 'guylaroche', 'label': 'Guy Laroche'},
{'value': 'helenarubinstein', 'label': 'Helena Rubinstein'},
{'value': 'house99', 'label': 'House 99'},
{'value': 'itcosmetics', 'label': 'It Cosmetics'},
{'value': 'kerastase', 'label': 'Kérastase'},
{'value': 'kiehls', 'label': "Kiehl's"},
{'value': 'laroche-posay', 'label': 'La Roche-Posay'},
{'value': 'lancome', 'label': 'Lancôme'},
{'value': 'lorealparis', 'label': "L'Oréal Paris"},
{'value': 'lorealparis-boots', 'label': "L'Oréal Paris Boots"},
{'value': 'lorealpro', 'label': "L'Oréal Professionnel"},
{'value': 'magic', 'label': 'Magic'},
{'value': 'maisonmargiela', 'label': 'Maison Margiela'},
{'value': 'matrix', 'label': 'Matrix'},
{'value': 'maybellineny', 'label': 'Maybelline NY'},
{'value': 'mizani', 'label': 'Mizani'},
{'value': 'niely', 'label': 'Niely'},
{'value': 'nyx', 'label': 'NYX'},
{'value': 'palomapicasso', 'label': 'Paloma Picasso'},
{'value': 'proenzaschouler', 'label': 'Proenza Schouler'},
{'value': 'pureology', 'label': 'Pureology'},
{'value': 'ralphlauren', 'label': 'Ralph Lauren'},
{'value': 'redken', 'label': 'Redken'},
{'value': 'rogergallet', 'label': 'Roger&Gallet'},
{'value': 'sanoflore', 'label': 'Sanoflore'},
{'value': 'seedphytonutrients', 'label': 'Seed Phytonutrients'},
{'value': 'shuuemura', 'label': 'Shu Uemura'},
{'value': 'shuuemuraartofhair', 'label': 'Shu Uemura Art of Hair'},
{'value': 'skinceuticals', 'label': 'SkinCeuticals'},
{'value': 'urbandecay', 'label': 'Urban Decay'},
{'value': 'vichy', 'label': 'Vichy'},
{'value': 'viktorrolf', 'label': 'Viktor & Rolf'},
{'value': 'yuesai', 'label': 'Yue Sai'},
{'value': 'yvessaintlaurent', 'label': 'Yves Saint Laurent'}];

const countries = [{'value': 'dmi', 'label': 'DMI'},
{'value': 'abw', 'label': 'Aruba'},
{'value': 'afg', 'label': 'Afghanistan'},
{'value': 'ago', 'label': 'Angola'},
{'value': 'aia', 'label': 'Anguilla'},
{'value': 'ala', 'label': 'Åland Islands'},
{'value': 'alb', 'label': 'Albania'},
{'value': 'and', 'label': 'Andorra'},
{'value': 'are', 'label': 'United Arab Emirates'},
{'value': 'arg', 'label': 'Argentina'},
{'value': 'arm', 'label': 'Armenia'},
{'value': 'asm', 'label': 'American Samoa'},
{'value': 'ata', 'label': 'Antarctica'},
{'value': 'atf', 'label': 'French Southern Territories'},
{'value': 'atg', 'label': 'Antigua and Barbuda'},
{'value': 'aus', 'label': 'Australia'},
{'value': 'aut', 'label': 'Austria'},
{'value': 'aze', 'label': 'Azerbaijan'},
{'value': 'bdi', 'label': 'Burundi'},
{'value': 'bel', 'label': 'Belgium'},
{'value': 'ben', 'label': 'Benin'},
{'value': 'bes', 'label': 'Bonaire, Sint Eustatius and Saba'},
{'value': 'bfa', 'label': 'Burkina Faso'},
{'value': 'bgd', 'label': 'Bangladesh'},
{'value': 'bgr', 'label': 'Bulgaria'},
{'value': 'bhr', 'label': 'Bahrain'},
{'value': 'bhs', 'label': 'Bahamas'},
{'value': 'bih', 'label': 'Bosnia and Herzegovina'},
{'value': 'blm', 'label': 'Saint Barthélemy'},
{'value': 'blr', 'label': 'Belarus'},
{'value': 'blz', 'label': 'Belize'},
{'value': 'bmu', 'label': 'Bermuda'},
{'value': 'bol', 'label': 'Bolivia, Plurinational State of'},
{'value': 'bra', 'label': 'Brazil'},
{'value': 'brb', 'label': 'Barbados'},
{'value': 'brn', 'label': 'Brunei Darussalam'},
{'value': 'btn', 'label': 'Bhutan'},
{'value': 'bvt', 'label': 'Bouvet Island'},
{'value': 'bwa', 'label': 'Botswana'},
{'value': 'caf', 'label': 'Central African Republic'},
{'value': 'can', 'label': 'Canada'},
{'value': 'cck', 'label': 'Cocos (Keeling) Islands'},
{'value': 'che', 'label': 'Switzerland'},
{'value': 'chl', 'label': 'Chile'},
{'value': 'chn', 'label': 'China'},
{'value': 'chn-tmall', 'label': 'China Tmall'},
{'value': 'civ', 'label': "Côte d'Ivoire"},
{'value': 'cmr', 'label': 'Cameroon'},
{'value': 'cod', 'label': 'Congo, The Democratic Republic of the'},
{'value': 'cog', 'label': 'Congo'},
{'value': 'cok', 'label': 'Cook Islands'},
{'value': 'col', 'label': 'Colombia'},
{'value': 'com', 'label': 'Comoros'},
{'value': 'cpv', 'label': 'Cabo Verde'},
{'value': 'cri', 'label': 'Costa Rica'},
{'value': 'cub', 'label': 'Cuba'},
{'value': 'cuw', 'label': 'Curaçao'},
{'value': 'cxr', 'label': 'Christmas Island'},
{'value': 'cym', 'label': 'Cayman Islands'},
{'value': 'cyp', 'label': 'Cyprus'},
{'value': 'cze', 'label': 'Czechia'},
{'value': 'deu', 'label': 'Germany'},
{'value': 'dji', 'label': 'Djibouti'},
{'value': 'dma', 'label': 'Dominica'},
{'value': 'dnk', 'label': 'Denmark'},
{'value': 'dom', 'label': 'Dominican Republic'},
{'value': 'dza', 'label': 'Algeria'},
{'value': 'ecu', 'label': 'Ecuador'},
{'value': 'egy', 'label': 'Egypt'},
{'value': 'eri', 'label': 'Eritrea'},
{'value': 'esh', 'label': 'Western Sahara'},
{'value': 'esp', 'label': 'Spain'},
{'value': 'est', 'label': 'Estonia'},
{'value': 'eth', 'label': 'Ethiopia'},
{'value': 'fin', 'label': 'Finland'},
{'value': 'fji', 'label': 'Fiji'},
{'value': 'flk', 'label': 'Falkland Islands (Malvinas)'},
{'value': 'fra', 'label': 'France'},
{'value': 'fro', 'label': 'Faroe Islands'},
{'value': 'fsm', 'label': 'Micronesia, Federated States of'},
{'value': 'gab', 'label': 'Gabon'},
{'value': 'gbr', 'label': 'United Kingdom'},
{'value': 'geo', 'label': 'Georgia'},
{'value': 'ggy', 'label': 'Guernsey'},
{'value': 'gha', 'label': 'Ghana'},
{'value': 'gib', 'label': 'Gibraltar'},
{'value': 'gin', 'label': 'Guinea'},
{'value': 'glp', 'label': 'Guadeloupe'},
{'value': 'gmb', 'label': 'Gambia'},
{'value': 'gnb', 'label': 'Guinea-Bissau'},
{'value': 'gnq', 'label': 'Equatorial Guinea'},
{'value': 'grc', 'label': 'Greece'},
{'value': 'grd', 'label': 'Grenada'},
{'value': 'grl', 'label': 'Greenland'},
{'value': 'gtm', 'label': 'Guatemala'},
{'value': 'guf', 'label': 'French Guiana'},
{'value': 'gum', 'label': 'Guam'},
{'value': 'guy', 'label': 'Guyana'},
{'value': 'hkg', 'label': 'Hong Kong'},
{'value': 'hmd', 'label': 'Heard Island and McDonald Islands'},
{'value': 'hnd', 'label': 'Honduras'},
{'value': 'hrv', 'label': 'Croatia'},
{'value': 'hti', 'label': 'Haiti'},
{'value': 'hun', 'label': 'Hungary'},
{'value': 'idn', 'label': 'Indonesia'},
{'value': 'imn', 'label': 'Isle of Man'},
{'value': 'ind', 'label': 'India'},
{'value': 'iot', 'label': 'British Indian Ocean Territory'},
{'value': 'irl', 'label': 'Ireland'},
{'value': 'irn', 'label': 'Iran, Islamic Republic of'},
{'value': 'irq', 'label': 'Iraq'},
{'value': 'isl', 'label': 'Iceland'},
{'value': 'isr', 'label': 'Israel'},
{'value': 'ita', 'label': 'Italy'},
{'value': 'jam', 'label': 'Jamaica'},
{'value': 'jey', 'label': 'Jersey'},
{'value': 'jor', 'label': 'Jordan'},
{'value': 'jpn', 'label': 'Japan'},
{'value': 'kaz', 'label': 'Kazakhstan'},
{'value': 'ken', 'label': 'Kenya'},
{'value': 'kgz', 'label': 'Kyrgyzstan'},
{'value': 'khm', 'label': 'Cambodia'},
{'value': 'kir', 'label': 'Kiribati'},
{'value': 'kna', 'label': 'Saint Kitts and Nevis'},
{'value': 'kor', 'label': 'Korea, Republic of'},
{'value': 'kwt', 'label': 'Kuwait'},
{'value': 'lao', 'label': "Lao People's Democratic Republic"},
{'value': 'lbn', 'label': 'Lebanon'},
{'value': 'lbr', 'label': 'Liberia'},
{'value': 'lby', 'label': 'Libya'},
{'value': 'lca', 'label': 'Saint Lucia'},
{'value': 'lie', 'label': 'Liechtenstein'},
{'value': 'lka', 'label': 'Sri Lanka'},
{'value': 'lso', 'label': 'Lesotho'},
{'value': 'ltu', 'label': 'Lithuania'},
{'value': 'lux', 'label': 'Luxembourg'},
{'value': 'lva', 'label': 'Latvia'},
{'value': 'mac', 'label': 'Macao'},
{'value': 'maf', 'label': 'Saint Martin (French part)'},
{'value': 'mar', 'label': 'Morocco'},
{'value': 'mco', 'label': 'Monaco'},
{'value': 'mda', 'label': 'Moldova, Republic of'},
{'value': 'mdg', 'label': 'Madagascar'},
{'value': 'mdv', 'label': 'Maldives'},
{'value': 'mex', 'label': 'Mexico'},
{'value': 'mhl', 'label': 'Marshall Islands'},
{'value': 'mkd', 'label': 'North Macedonia'},
{'value': 'mli', 'label': 'Mali'},
{'value': 'mlt', 'label': 'Malta'},
{'value': 'mmr', 'label': 'Myanmar'},
{'value': 'mne', 'label': 'Montenegro'},
{'value': 'mng', 'label': 'Mongolia'},
{'value': 'mnp', 'label': 'Northern Mariana Islands'},
{'value': 'moz', 'label': 'Mozambique'},
{'value': 'mrt', 'label': 'Mauritania'},
{'value': 'msr', 'label': 'Montserrat'},
{'value': 'mtq', 'label': 'Martinique'},
{'value': 'mus', 'label': 'Mauritius'},
{'value': 'mwi', 'label': 'Malawi'},
{'value': 'mys', 'label': 'Malaysia'},
{'value': 'myt', 'label': 'Mayotte'},
{'value': 'nam', 'label': 'Namibia'},
{'value': 'ncl', 'label': 'New Caledonia'},
{'value': 'ner', 'label': 'Niger'},
{'value': 'nfk', 'label': 'Norfolk Island'},
{'value': 'nga', 'label': 'Nigeria'},
{'value': 'nic', 'label': 'Nicaragua'},
{'value': 'niu', 'label': 'Niue'},
{'value': 'nld', 'label': 'Netherlands'},
{'value': 'nor', 'label': 'Norway'},
{'value': 'npl', 'label': 'Nepal'},
{'value': 'nru', 'label': 'Nauru'},
{'value': 'nzl', 'label': 'New Zealand'},
{'value': 'omn', 'label': 'Oman'},
{'value': 'pak', 'label': 'Pakistan'},
{'value': 'pan', 'label': 'Panama'},
{'value': 'pcn', 'label': 'Pitcairn'},
{'value': 'per', 'label': 'Peru'},
{'value': 'phl', 'label': 'Philippines'},
{'value': 'plw', 'label': 'Palau'},
{'value': 'png', 'label': 'Papua New Guinea'},
{'value': 'pol', 'label': 'Poland'},
{'value': 'pri', 'label': 'Puerto Rico'},
{'value': 'prk', 'label': "Korea, Democratic People's Republic of"},
{'value': 'prt', 'label': 'Portugal'},
{'value': 'pry', 'label': 'Paraguay'},
{'value': 'pse', 'label': 'Palestine, State of'},
{'value': 'pyf', 'label': 'French Polynesia'},
{'value': 'qat', 'label': 'Qatar'},
{'value': 'reu', 'label': 'Réunion'},
{'value': 'rou', 'label': 'Romania'},
{'value': 'rus', 'label': 'Russian Federation'},
{'value': 'rwa', 'label': 'Rwanda'},
{'value': 'sau', 'label': 'Saudi Arabia'},
{'value': 'sdn', 'label': 'Sudan'},
{'value': 'sen', 'label': 'Senegal'},
{'value': 'sgp', 'label': 'Singapore'},
{'value': 'sgs', 'label': 'South Georgia and the South Sandwich Islands'},
{'value': 'shn', 'label': 'Saint Helena, Ascension and Tristan da Cunha'},
{'value': 'sjm', 'label': 'Svalbard and Jan Mayen'},
{'value': 'slb', 'label': 'Solomon Islands'},
{'value': 'sle', 'label': 'Sierra Leone'},
{'value': 'slv', 'label': 'El Salvador'},
{'value': 'smr', 'label': 'San Marino'},
{'value': 'som', 'label': 'Somalia'},
{'value': 'spm', 'label': 'Saint Pierre and Miquelon'},
{'value': 'srb', 'label': 'Serbia'},
{'value': 'ssd', 'label': 'South Sudan'},
{'value': 'stp', 'label': 'Sao Tome and Principe'},
{'value': 'sur', 'label': 'Suriname'},
{'value': 'svk', 'label': 'Slovakia'},
{'value': 'svn', 'label': 'Slovenia'},
{'value': 'swe', 'label': 'Sweden'},
{'value': 'swz', 'label': 'Eswatini'},
{'value': 'sxm', 'label': 'Sint Maarten (Dutch part)'},
{'value': 'syc', 'label': 'Seychelles'},
{'value': 'syr', 'label': 'Syrian Arab Republic'},
{'value': 'tca', 'label': 'Turks and Caicos Islands'},
{'value': 'tcd', 'label': 'Chad'},
{'value': 'tgo', 'label': 'Togo'},
{'value': 'tha', 'label': 'Thailand'},
{'value': 'tjk', 'label': 'Tajikistan'},
{'value': 'tkl', 'label': 'Tokelau'},
{'value': 'tkm', 'label': 'Turkmenistan'},
{'value': 'tls', 'label': 'Timor-Leste'},
{'value': 'ton', 'label': 'Tonga'},
{'value': 'tto', 'label': 'Trinidad and Tobago'},
{'value': 'tun', 'label': 'Tunisia'},
{'value': 'tur', 'label': 'Turkey'},
{'value': 'tuv', 'label': 'Tuvalu'},
{'value': 'twn', 'label': 'Taiwan, Province of China'},
{'value': 'tza', 'label': 'Tanzania, United Republic of'},
{'value': 'uga', 'label': 'Uganda'},
{'value': 'ukr', 'label': 'Ukraine'},
{'value': 'umi', 'label': 'United States Minor Outlying Islands'},
{'value': 'ury', 'label': 'Uruguay'},
{'value': 'usa', 'label': 'United States'},
{'value': 'uzb', 'label': 'Uzbekistan'},
{'value': 'vat', 'label': 'Holy See (Vatican City State)'},
{'value': 'vct', 'label': 'Saint Vincent and the Grenadines'},
{'value': 'ven', 'label': 'Venezuela, Bolivarian Republic of'},
{'value': 'vgb', 'label': 'Virgin Islands, British'},
{'value': 'vir', 'label': 'Virgin Islands, U.S.'},
{'value': 'vnm', 'label': 'Viet Nam'},
{'value': 'vut', 'label': 'Vanuatu'},
{'value': 'wlf', 'label': 'Wallis and Futuna'},
{'value': 'wsm', 'label': 'Samoa'},
{'value': 'yem', 'label': 'Yemen'},
{'value': 'zaf', 'label': 'South Africa'},
{'value': 'zmb', 'label': 'Zambia'},
{'value': 'zwe', 'label': 'Zimbabwe'}];

export default function ExportJson(props) {

  const classes = useStyles();
  const [overlay,setOverlay] = React.useContext(OverlayContext);

  const [open, setOpen] = React.useState(true);
  const [formData, setFormData] = React.useState({});

  const [newexport, setNewExport] = React.useState(true);

  const [loading,setLoading] = React.useState(false);
  const [matrixData,setMatrixData] = React.useState({steps:[],columns:[],data:[],inputs:[]});

  const hotTable = React.useRef(null);

  const handleSwitchChange = event => {
    setNewExport(!newexport);
  };

  // JSON parameters Initialisation
  const [brand, setBrandCode] = React.useState("");
  const handleChangeBrand = event => {
    setBrandCode(event.target.value);
    handleChange(event);
  };

  const [country, setCountry] = React.useState("");
  const handleChangeCountry = event => {
    setCountry(event.target.value);
    handleChange(event);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const title = "Export DSF JSON";

  const handleChange = (event) => {
      // pluralsight.com/guides/handling-multiple-inputs-with-single-onchange-handler-react
      const value = event.target.value;
      setFormData({
        ...formData,
        [event.target.name]: value,
      });
  }

  const handleExport = () => {
    setOverlay(true);
    handleClose();

    // Prepare API options and config
    var options = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Request-Headers': 'Content-Type, Authorization'
      },
    }
    const DATABRICKS_JOB_ID= (process.env.REACT_APP_STAGE !== 'prod') ? 4 : 5;

    options["body"] = JSON.stringify({formData, "state" : newexport,
                                                "job_id" : DATABRICKS_JOB_ID});
    // API HTTP request
    fetch(process.env.REACT_APP_API_URL + 'prescription/dsf/jsonexport', options)
    .then(response => response.json())
    .then(data => {

        if (data["error"] == false){
            cogoToast.success(data["message"], { position: 'bottom-left'});
        } else {
            cogoToast.error(data["message"], { position: 'bottom-left'});
        }

        setOverlay(false);

    })
    .catch(function(error) {
        cogoToast.error("Error, see console for more info", { position: 'bottom-left'});
        console.log('Il y a eu un problème avec l\'opération fetch: ' + error.message);
        setOverlay(false);
    });
  }

  const handleJSONInfo = () => {
    // Prepare API options and config
    var options = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Request-Headers': 'Content-Type, Authorization'
      },
    }
    // API HTTP request
    fetch(process.env.REACT_APP_API_URL + 'prescription/dsf/json', options)
    .then(response => response.json())
    .then(data => {
        if (data["error"]){
          setLoading(false);
          cogoToast.error('Error: ' + data["message"],{ position: 'bottom-left'});
        } else {
          // Set matrix data for handson table
          let columns = data["data"]["columns"];
          let rowData = data["data"]["data"].map(el => Object.values(el));
          setMatrixData({
            columns:columns,
            data:rowData,
          });
          setLoading(false);

          cogoToast.success("Gathered JSONs information successfully", { position: 'bottom-left'});
        }
    })
    .catch(function(error) {
        setLoading(false);
        cogoToast.error("There was an error when getting JSONs info", { position: 'bottom-left'});
        console.log('Il y a eu un problème avec l\'opération fetch: ' + error.message);
    });
  }

  let settings = {
    data:matrixData["data"],
    colHeaders:matrixData["columns"],
    rowHeaders:true,
    dropdownMenu: true,
    filters: true,
    columnSorting: true,
    editor:false,
    width:"100%",
    height:"500",
    licenseKey: 'non-commercial-and-evaluation',
  }

  return (
    <span>
      <Card>
        <CardBody>      
          <form className={classes.root} noValidate autoComplete="off">
            <div>
              <FormControlLabel
                control={
                  <Switch checked={newexport} onChange={handleSwitchChange}/>
                }
                label={newexport ? "EXISTING JSON" : "NO EXISTING JSON"}
              />
            </div>
            <div>
              {/* 1. Brand Selection ------------------------------------------- */}
              <TextField
                name="environment"
                label="environment"
                required
                variant="outlined"
                onChange={handleChange}
                helperText="Usually the Airtable name"
              />
              {/* 2. Brand Code Selection ------------------------------------------- */}
              <TextField
                className={classes.textField}
                name="brand"
                label="brand"
                select
                variant={newexport ? "filled" : "outlined"}
                onChange={handleChangeBrand}
                helperText="Please select your Brand"
                disabled={newexport}
              >
                {brands.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
              {/* 3. Country Code Selection ------------------------------------------- */}
              <TextField
                name="country"
                label="country"
                //select
                //value={country}
                variant={newexport ? "filled" : "outlined"}
                //onChange={handleChangeCountry}
                onChange={handleChange}
                helperText="Country ISO3 code (-language). ex: che-de"
                disabled={newexport}
              >
                {countries.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
              {/* 4. Touchpoint Code Selection ------------------------------------------- */}
              <TextField
                className={classes.textField}
                name="touchpoint"
                label="touchpoint code"
                required
                variant= "outlined"
                onChange={handleChange}
                herlperText="Please give touchpoint code"
              />
              {/* 5. Catalog Version ------------------------------------------- */}
              <TextField
                className={classes.textField}
                name="catalogversion"
                label="catalog version"
                variant={newexport ? "filled" : "outlined"}
                onChange={handleChange}
                herlperText="Please give Catalog Version"
                disabled={newexport}
              />
            </div>
            <div>
              <Button onClick={handleExport} color="primary">
                  Export
              </Button>

              <Button onClick={handleJSONInfo} color="primary">
                  Get Jsons Information
              </Button>
              {matrixData["data"].length < 1 ?
                "" :
                <Grid item xs={12} className={classes.paper}>
                  <HotTable
                    settings={settings}
                    ref={hotTable}
                  />
                </Grid>
                }
            </div>
          </form>
        </CardBody>
      </Card>
    </span>
  );
}
