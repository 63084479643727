import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";

// Other components
import routes from "routes.js";
import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";
import bgImage from "assets/img/model_lancome.png";
import logo from "assets/img/logo.png";
import DataModel from 'datamodel/datamodel';

// External components
import LoadingOverlay from 'react-loading-overlay';
import { UserDataContext } from "../index";


let ps;


// Contexts
// great tuto at https://upmostly.com/tutorials/how-to-use-the-usecontext-hook-in-react 
export const EnvironmentContext = React.createContext({
  // Optional arguments
  env: "Lancome-DMI",
  setEnv: () => {}
});

export const ProjectContext = React.createContext({
  // Optional arguments
  project: "loreal_skindr",
  setProject: () => {}
});

export const DataModelContext = React.createContext({});
export const OverlayContext = React.createContext(false);


const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    })}
    <Redirect from="/" to="/home" />
  </Switch>
);

const useStyles = makeStyles(styles);




export default function Admin({ ...rest }) {
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions

  const [userData,setUserData] = React.useContext(UserDataContext);
  const [image, setImage] = React.useState(bgImage);
  const [color, setColor] = React.useState("blue");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  
  // State linked to contexts
  const dm = new DataModel({data:{}});
  const [env, setEnv] = React.useState(userData.available_datamodels[0]);
  const [project, setProject] = React.useState(userData.available_project_names[0]);
  const [dataModel, setDataModel] = React.useState(dm);
  const [overlay, setOverlay] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);


  // Render
  return (
    <ProjectContext.Provider value = {[project,setProject]}>
      <EnvironmentContext.Provider value = {[env,setEnv]}>
        <DataModelContext.Provider value = {[dataModel,setDataModel]}>
          <OverlayContext.Provider value = {[overlay,setOverlay]}>
          <LoadingOverlay
            active={overlay}
            spinner
          >
          <div className={classes.wrapper}>
            <Sidebar
              routes={routes}
              logoText={"Eki.SkinDr"}
              logo={logo}
              image={image}
              handleDrawerToggle={handleDrawerToggle}
              open={mobileOpen}
              color={color}
              {...rest}
            />
            <div className={classes.mainPanel} ref={mainPanel}>
              <Navbar
                routes={routes}
                handleDrawerToggle={handleDrawerToggle}
                {...rest}
              />
              <div className={classes.content}>
                <div className={classes.container}>{switchRoutes}</div>
              </div>
              <Footer />
            </div>
          </div>
          </LoadingOverlay>
          </OverlayContext.Provider>
        </DataModelContext.Provider>
      </EnvironmentContext.Provider>
    </ProjectContext.Provider>
  );
}
