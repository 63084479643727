import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Chip from '@material-ui/core/Chip';


const useStyles = makeStyles(theme => ({
  root: {
    width:"100%",
    minHeight:"auto",
    textOverflow: 'ellipsis', 
    justifyContent: 'center',
    '& > *': {
        margin: theme.spacing(0.5),
    },
  },

  details:{
    fontFamily:"MetricLight !important",
    textOverflow: 'ellipsis',
    display:"block",
    textAlign:"center",
  },
  chip:{
    margin:"4px",
  },
  header:{
    // margin:"8px",
  },
  heading: {
    fontWeight:"300",
    fontSize: "12px !important",
  },
  heading2: {
    fontSize: "12px !important",
    color:"gray",
    display:"flex",
    fontStyle:"italic",
    fontWeight:"300",
    marginLeft:"4px",
  },
}));

export default function ProductsByRoutineSteps(props) {
  const classes = useStyles();

  const handleClick = label => () => {
    console.log("Clicked on ",label);
    props.handleSelectProduct(label);
    // props.selectProduct(label);
  }

  return (
    <div className={classes.root}>
    {
        props.data.map(el => (
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className={classes.header}
            >
              <Typography className={classes.heading}>
                  {el.routine_step_name}
              </Typography>
              <Typography className={classes.heading2}>
                  - {el.products.length} product{el.products.length > 1 ? 's' : ''}  
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.details}>
                {
                    el.products.map(product => (
                        <Chip 
                          size="small" 
                          label={product} 
                          clickable         
                          // onDelete={props.openProductInfo(product["product_name"])}
                          // deleteIcon={<InfoOutlinedIcon />}
                          onClick={handleClick(product)} 
                          className={classes.chip} 
                          // style={{backgroundColor:"#00add0"}}
                          />
                    ))
                }
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ))
    }
    </div>
  );
}
