import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import HomepageBlock from "./HomepageBlock";
import Typography from "@material-ui/core/Typography";
import Grid from '@material-ui/core/Grid';
// core components


const styles = {
  title:{
    fontSize:18,
    fontWeight:400,
    // color:"#00add0",
    textAlign:"center",
    marginBottom:32
  }
};

const useStyles = makeStyles(styles);

export default function Homepage() {
  const classes = useStyles();
  return (
    <div>
      {/* <Typography variant="h4" component="h4" className={classes.title}>
        Welcome to the Eki.SkinDr tool!
      </Typography> */}
      <Grid container spacing={4}>
        <HomepageBlock 
          title="Prescription" 
          description="Simulate a prescription recommended to a consumer profile using the Ekimetrics algorithm."
          href="/prescription"
        />
        <HomepageBlock 
          title="Products" 
          description="Explore the list of possible recommended products, their strengths and how they compare to one another."
          href="/products"
        />
        <HomepageBlock 
          title="Analysis" 
          description="Analyze all prescriptions to understand most recommended products and rules behind the algorithm."
          href="/analysis"
        />
        <HomepageBlock 
          title="Documentation" 
          description="Learn more about the algorithm, the tool and the project on the SkinDr documentation website."
          href="/documentation"
        />
        {/* <HomepageBlock 
          title="Checks" 
          description="To be released soon. Checks everything is well configured in the Airtable."
          href="/checks"
          disable={true}
        />
        <HomepageBlock 
          title="Admin" 
          description="To be released soon. Handle localization easily through this admin panel."
          href="/admin"
          disable={true}
        /> */}
      </Grid>
    </div>
  );
}
