/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import BubbleChart from "@material-ui/icons/BubbleChart";
import Notifications from "@material-ui/icons/Notifications";
import HomeIcon from '@material-ui/icons/Home';
import Help from "@material-ui/icons/Help";
import Explore from "@material-ui/icons/Explore";
import SettingsIcon from '@material-ui/icons/Settings';
// core components/views for Admin layout
import EmptyPage from "views/SkinDr/EmptyPage.js";
import Documentation from "views/SkinDr/Documentation.js";
import PrescriptionView from "views/PrescriptionTab/PrescriptionView.js";
import Checks from "views/SkinDr/Checks.js";
import ProductsView from "views/ProductsTab/ProductsView.js";
import AnalysisView from "views/AnalysisTab/AnalysisView.js";
import Homepage from "views/HomepageTab/Homepage";
import ConfigurationView from "views/ConfigurationTab/ConfigurationView.js";
import DocumentationView from "views/DocumentationTab/DocumentationView.js";

const dashboardRoutes = [
  {
    path: "/home",
    name: "Home",
    icon: HomeIcon,
    component: Homepage,
    layout: "/admin"
  },
  {
    path: "/prescription",
    name: "Prescription",
    icon: BubbleChart,
    component: PrescriptionView,
    layout: "/admin"
  },
  // {
  //   path: "/checks",
  //   name: "Checks",
  //   icon: Notifications,
  //   component: Checks,
  //   layout: "/admin"
  // },
  {
    path: "/products",
    name: "Products",
    icon: Explore,
    component: ProductsView,
    layout: "/admin"
  },
  {
    path: "/analysis",
    name: "Analysis",
    icon: Dashboard,
    component: AnalysisView,
    layout: "/admin"
  },
  // {
  //   path: "/data",
  //   name: "Data",
  //   icon: Dashboard,
  //   component: DataView,
  //   layout: "/admin"
  // },
  {
    path: "/docs",
    name: "Documentation",
    icon: Help,
    component: DocumentationView,
    layout: "/admin"
  },
  {
    path: "/config",
    name: "Configuration",
    icon: SettingsIcon,
    component: ConfigurationView,
    layout: "/admin"
  },
];

export default dashboardRoutes;
