import React, { Component } from "react";
import Slider from "react-slick";
import ProductCard from './ProductCard';

// function Product(props){

//     return (
//     <ProductCard
//     header={`Product ${props.number}`}
//           img={"https://www.nocibe.fr/bibliotheque/produits/BIOTHERM/231963_biotherm_aqua_super_concentrate_gel_aqua_bounce_hydratation_rebond_gel_hydratation_rebond_format_50ml_1000x1000.jpg?frz-v=2069"}
//           name={"AQUASOURCE HYDRATING & TONIFYING TONER"}
//           desc={""}
//           tags={[]}
//           score={12}
//           alternatives={[]}
//           data={{}} />)

// }

// function SampleNextArrow(props) {
//     const { className, style, onClick } = props;
//     return (
//       <div
//         className={className}
//         style={{ ...style, display: "block", color: "black" }}
//         onClick={onClick}
//       />
//     );
//   }
  
//   function SamplePrevArrow(props) {
//     const { className, style, onClick } = props;
//     return (
//       <div
//         className={className}
//         style={{ ...style, display: "block", color: "black" }}
//         onClick={onClick}
//       />
//     );
//   }



export default function SimpleSlider(props){

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        rows:props.components.length > 4 ? 2 : 1,
        slidesPerRow: 4,
        // nextArrow: <SampleNextArrow />,
        // prevArrow: <SamplePrevArrow />,
        // adaptiveHeight: true,
        responsive: [
            {
              breakpoint: 1100,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                slidesPerRow: 1,
                rows:1,
              }
            }
          ]
    };


    return (
        <div>
          <Slider {...settings}>
          {/* {
              props.list.map(el => (
                <div>
                  <Product number={el}/>
                </div>
              ))
          } */}
          {
              props.components.map(el => (
                <div style={{"textAlign":"center"}}>
                    <div style={{"margin":"auto"}}>
                    {el}
                    </div>
                </div>
              ))
          }
          </Slider>
        </div>
  
      )

}
