import React from 'react';
import ReactApexChart from "react-apexcharts";


let BASE_OPTIONS = {
  options: {
    chart: {
      stacked: true,
      toolbar: {
        show: true
      },
      zoom: {
        enabled: true
      }
    },

    grid: {
      xaxis: {
        showLines: false
      },
      yaxis: {
        showLines: false
      }
    },


    theme: {
      mode: 'light',
      palette: 'palette2',
      monochrome: {
          enabled: false,
          color: '#00add0',
          shadeTo: 'light',
          shadeIntensity: 1
      },
    },
  
  
  
    responsive: [{
      breakpoint: 480,
      options: {
        legend: {
          position: 'bottom',
          offsetX: -10,
          offsetY: 0
        }
      }
    }],
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },

    xaxis: {
    },
    legend: {
      position: 'bottom',
      offsetY: 0
    },
    fill: {
      opacity: 1
    }
  },
}

export default function MatchedInputs(props){

    function parseData(data){
        // Prepare data
        let series = {};
        let productsList = [];
        let products = [data.recommendation].concat(data.alternatives);

        // Reducer for sum
        const reducer = (accumulator, currentValue) => accumulator + currentValue;

        // Prepare data
        products.forEach(el => {
            let inputs = el.matched_inputs;
            Object.keys(inputs).forEach(input => {
                if (Object.keys(series).includes(input)){
                    series[input].push({product_name:el.product,weight:inputs[input]})
                } else {
                    series[input] = [{product_name:el.product,weight:inputs[input]}]
                }
            })
            let totalWeight = Object.values(inputs).reduce(reducer);
            productsList.push({product_name:el.product,total_weight:totalWeight});
    
        })

        // Sort products
        productsList = productsList.sort((a,b) => b.total_weight - a.total_weight).map(el => (el.product_name))        
    
        // Prepare final dataset
        let newSeries = [];
        Object.keys(series).forEach(el => {
            let keyData = {name:el};
            let keyValues = series[el];
            let newValues = productsList.map(product=>{
                let weight = keyValues.find(value => value.product_name===product);
                if (weight === undefined){
                    return 0;
                } else {
                    return weight.weight
                }
            })
            keyData["data"] = newValues;
            newSeries.push(keyData);
        })
    
        return {
            products:productsList,
            series:newSeries,
        }
    }
    
    let parsedData = parseData(props.data);
    let data = BASE_OPTIONS;
    data["options"]["xaxis"]["categories"] = parsedData["products"];
    data["series"] = parsedData["series"]

    console.log(data);

    return (
        <div id="chart">
          <ReactApexChart options={data.options} series={data.series} type="bar"/>
        </div>
    )
}


