import React from "react";
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
// @material-ui/icons
import Public from "@material-ui/icons/Public";

// core components
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";
import { EnvironmentContext } from '../../layouts/Admin';
import { ProjectContext } from '../../layouts/Admin';
import { UserDataContext } from '../../index';

const useStyles = makeStyles(styles);





export default function ProjectButton(props) {
  const classes = useStyles();

  // Context
  const [project,setProject] = React.useContext(ProjectContext);
  const [env,setEnv] = React.useContext(EnvironmentContext);
  const [userData,setUserData] = React.useContext(UserDataContext);

  // State
  const [openNotification, setOpenNotification] = React.useState(null);
  const [NotifNumber, setNotifNumber] = React.useState(0);
//   const [environment, setEnvironment] = React.useState(env);

  // Methods
  const handleClickNotification = event => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };

  const handleCloseNotification = () => {
    setOpenNotification(null);
  };

  // Change project
  const handleClickProject = value => () => {
    handleCloseNotification();
    setProject(value);
    setEnv(userData.available_projects_datamodels[value][0])
  }

  // Update notif name
  React.useEffect(()=>{
    // if (props.userData != undefined){
    setNotifNumber(userData["available_project_names"].length)

  },[userData])

  return (
    <div>
        <Button
            color={window.innerWidth > 959 ? "transparent" : "white"}
            justIcon={window.innerWidth > 959}
            simple={!(window.innerWidth > 959)}
            aria-owns={openNotification ? "notification-menu-list-grow" : null}
            aria-haspopup="true"
            onClick={handleClickNotification}
            className={classes.buttonLink}
        >
            <Public className={classes.icons} />
            {
            NotifNumber > 1 ? <span className={classes.notifications}>{NotifNumber}</span> : ""
            }
            <Hidden mdUp implementation="css">
            <p onClick={handleCloseNotification} className={classes.linkText}>
                Datasets
            </p>
            </Hidden>
        </Button>
        <Poppers
            open={Boolean(openNotification)}
            anchorEl={openNotification}
            transition
            disablePortal
            className={
            classNames({ [classes.popperClose]: !openNotification }) +
            " " +
            classes.popperNav
            }
        >
            {({ TransitionProps, placement }) => (
            <Grow
                {...TransitionProps}
                id="notification-menu-list-grow"
                style={{
                transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom"
                }}
            >
                {/* <Paper>
                Yo
                </Paper> */}
                <Paper>
                <ClickAwayListener onClickAway={handleCloseNotification}>
                    <MenuList role="menu">
                    {
                        userData.available_project_names.sort().map(value => {
                        return (
                            <MenuItem onClick={handleClickProject(value)} className={classes.dropdownItem}>
                            {value}
                            </MenuItem>
                        )
                        })
                    }
                    </MenuList>
                </ClickAwayListener>
                </Paper>
            </Grow>
            )}
        </Poppers>
        </div>
  );
}
