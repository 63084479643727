import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Fab from '@material-ui/core/Fab';

const styles = {
  button:{
    background: "#00add0",
    background:'linear-gradient(223deg, rgba(0,104,125,1) 0%, rgba(0,173,208,1) 100%)',
    color:"white",
    marginRight:16,
    marginLeft:16,
  },
};

const useStyles = makeStyles(styles);


export default function StyledButton(props) {
  const classes = useStyles();

  return (
    <Fab variant="extended" size="medium" className={classes.button} onClick={props.onClick}>
        {props.text}
    </Fab>
  );
}
