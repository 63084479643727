import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";

// @material-ui/icons
import LoopIcon from '@material-ui/icons/Loop';

// core components
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";
import { EnvironmentContext } from '../../layouts/Admin';
import { ProjectContext } from '../../layouts/Admin';
import { DataModelContext } from '../../layouts/Admin';
import { OverlayContext } from '../../layouts/Admin';
import DataModel from 'datamodel/datamodel';
import cogoToast from 'cogo-toast';


const useStyles = makeStyles(styles);

export default function RefreshButton(props) {
  const classes = useStyles();

  // Context
  const [env,setEnv] = React.useContext(EnvironmentContext);
  const [project,setProject] = React.useContext(ProjectContext);
  const [dataModel,setDataModel] = React.useContext(DataModelContext);
  const [overlay,setOverlay] = React.useContext(OverlayContext);
  const [fetching,setFetching] = React.useState(false);

  // Change
  React.useEffect(() => {

    if (!overlay){
      if (!fetching){
        fetchMongoDB();
      }
    }
  },[env]);


  // Refresh from Airtable
  const handleClickRefresh = () => {
    console.log("Refreshing data from Airtable");
    setOverlay(true)
    var options = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Request-Headers': 'Content-Type, Authorization'
      },
    }

    // Choose correct environment
    // 1. brandcode-country
    // 2. brandcode-country-lang
    // 3. brandcode-country/touchpoint
    // bestnaming: brandcode-country-lang-touchpoint
    let project_name = project
    let environment = env.split("-");
    let brand = environment[0];
    environment.shift()
    let country = environment.join("-");
    let params = `brand=${brand}&country=${country}&project_name=${project_name}`;
    console.log("updating ", environment)
    console.log("brand name is ", brand)
    console.log("project name is ", project_name)
    // MODIFCATION TEST PR

    fetch(process.env.REACT_APP_API_URL + `app/datamodel?${params}`,options)
      .then(response => response.json())
      .then(data => {

        if (data["error"]){
          console.log(data)
          cogoToast.error(data["message"], {'hideAfter':15});
          // cogoToast.error(data["message"], { position: 'bottom-left'});
          setOverlay(false);
        } else {
          let dm = new DataModel({data:data["datamodel"]});
          cogoToast.success("Update from Airtable has succeeded", { position: 'bottom-left'});
          setDataModel(dm);
          setOverlay(false);
        }

      })
      .catch(function(error) {
        setOverlay(false);
        cogoToast.error("Update from Airtable has failed, see console for more info", { position: 'bottom-left'});
        console.log('Il y a eu un problème avec l\'opération fetch: ' + error.message);
    });
  }

  // Refresh 
  const fetchMongoDB = () => {
    // setOverlay(true);
    setFetching(true);
    console.log(`Requesting env datamodel for ${env}`);

    var options = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Request-Headers': 'Content-Type, Authorization'
      },
    }


    // Choose correct environment
    let project_name = project
    let environment = env.split("-");
    let brand = environment[0];
    environment.shift()
    let country = environment.join("-");
    let params = `brand=${brand}&country=${country}&project_name=${project_name}`;

    // API HTTP request
    fetch(process.env.REACT_APP_API_URL + 'app/datamodel?' + params,options)
    .then(response => response.json())
    .then(data => {

        if (data["error"]){
          console.log(data)
          cogoToast.error(data["message"], { position: 'bottom-left'});
        } else {
          let dm = new DataModel({data:data["datamodel"]});
          console.log(dm);
          setDataModel(dm);
          // setOverlay(false);
          cogoToast.success(env + " data was successfully loaded", { position: 'bottom-left'});
        }
        setFetching(false);

    })
    .catch(function(error) {
      // setOverlay(false);
      cogoToast.error("Loading data has failed, contact Ekimetrics or see console for more info", { position: 'bottom-left'});
      console.log('Il y a eu un problème avec l\'opération fetch: ' + error.message);
      setFetching(false);
    });
  };


  return (
      <div className={classes.manager}>
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-haspopup="true"
          onClick={handleClickRefresh}
          className={classes.buttonLink}
        >
          <LoopIcon className={classes.icons} />
          <Hidden mdUp implementation="css">
            <p className={classes.linkText}>Refresh data</p>
          </Hidden>
        </Button>
      </div>
  );
}
