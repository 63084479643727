import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import PrescriptionForm from "./PrescriptionForm";
// core components
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

// Custom components
import Routine from './Routine';
import { EnvironmentContext } from '../../layouts/Admin';
import Slider from './Slider';


const styles = {
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative"
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px"
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};


const useStyles = makeStyles(styles);

export default function PrescriptionView() {

  // Style
  const classes = useStyles();

  // States
  const [products, setProducts] = React.useState([]);
  const [env,setEnv] = React.useContext(EnvironmentContext);

  // Functions
  const handleProductsChange = (products) => {
    setProducts(products);
    console.log(products);
  }

  // Change
  React.useEffect(() => {
    setProducts([]);
  },[env]);


  return (
    <div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={3}>
            <PrescriptionForm onProductsChange={handleProductsChange} products={products}/>
          </Grid>
          <Grid item xs={12} sm={12} md={9} style={{paddingRight:"20px"}}>
            <Box>
            {products.map(routine => {
                return <Routine steps={routine["steps"]} routine = {routine["routine_name"]}/>
              })}
            </Box>
            {/* <Box display="flex" alignItems="center" justifyContent="center" flexWrap="wrap" className={classes.rightBox}>
              {products.map(routine => {
                return <Routine steps={routine["steps"]} routine = {routine["routine_name"]}/>
              })}
            </Box> */}
          </Grid>
        </Grid>
    </div>
  );
}
