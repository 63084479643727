import React from "react";
// @material-ui/core components
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// Plotly
// Normally it's the line below that works, but because of RAM consumption, another solution had to be found
// See https://github.com/plotly/react-plotly.js/issues/135 for more information
// import Plot from 'react-plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';
const Plotly = window.Plotly;
const Plot = createPlotlyComponent(Plotly);


const styles = {
    root: {
        width:"100%",
        marginBottom:"32px"
    },
    title:{
        fontSize:14,
        fontWeight:700,
        color:"#00add0"
    },
    img:{
        '&:hover': {
            cursor:"pointer",
        },
        maxHeight:90,
    },
    routine_step:{
        fontSize:14,
        width:"100%"
    },
    desc:{
        fontSize:12,
    },
    button:{
        background: "#00add0",
        background:'linear-gradient(223deg, rgba(0,104,125,1) 0%, rgba(0,173,208,1) 100%)',
        color:"white",
        marginTop:8,
      },
    dialogTitle:{
        fontSize:"14px !important",
    },
    dialogContent:{
        width:"100%",
    },
    plot:{
        width:"100%",
    },
    chipParcats:{
        marginRight:8,
    }
};
    
const useStyles = makeStyles(styles);    


const HtmlTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 350,
      fontSize: 12,
      border: '1px solid #dadde9',
      fontWeight:300,
    },
  }))(Tooltip);




const buildIndexList = (data,col) => {
    let columns = data["inputs"].concat(data["steps"]);
    let okColumns = data["inputs"].filter(el => !(el.includes("range"))).concat([col])
    let indexList = okColumns.map(el => columns.indexOf(el));
    return [okColumns,indexList];
}


const groupByOnIndexList = (data,indexList) => {
    let count = {}
    data.forEach(row => {
        let key = indexList.map(i => row[i]).join("||")
        if (key in count){
            count[key] += 1
        } else {
            count[key] = 1
        }
    })

    count = Object.keys(count).map(el => {
        return {
            values:el.split("||"),
            count:count[el]            
        }
    })
    return count;
}


const allEqual = arr => {
    return arr.every( (val, i, arr) => val === arr[0])
}


const isUseful = (data,index,indexTarget) => {

    let groupedData = groupByOnIndexList(data,[index,indexTarget]);
    let weirdCount = {}
    groupedData.forEach(row => {
        let target = row.values[1];
        let accValue = {value:row.values[0],count:row.count}
        if (target in weirdCount){
            weirdCount[target].push(accValue);
        } else {
            weirdCount[target] = [accValue];
        }
    })

    // Condition on values
    let conditionValues = Object.values(weirdCount).map(el => el.map(el2 => el2.value).join("|"));
    if (!allEqual(conditionValues)){
        return true;
    }

    // Condition on count
    let conditionCount = Object.values(weirdCount).map(el => allEqual(el.map(el2 => el2.count))).every(el => el);
    return !conditionCount;
}


function ParallelCategories(props){

    const classes = useStyles();


    let [okColumns,indexList] = buildIndexList(props.parcatsData,props.step_name);

    // Find useful dimensions
    let newIndexList = [];
    let newOkColumns = [];
    let nokColumns = [];
    let indexTarget = indexList[indexList.length - 1];
    indexList.forEach((index,i) => {
        if (index != indexTarget){
            let usefulCol = isUseful(props.parcatsData["data"],index,indexTarget);
            if (usefulCol){
                newIndexList.push(index);
                newOkColumns.push(okColumns[i]);
            } else {
                nokColumns.push(okColumns[i]);
            }

        } else {
            newIndexList.push(index);
            newOkColumns.push(okColumns[i]);
        }
    })

    indexList = newIndexList;
    okColumns = newOkColumns;


    let groupedData = groupByOnIndexList(props.parcatsData["data"],indexList);
    let products = props.products.map(el => el.productName);

    var dimensions = []
    var counts = []
    var colors = []


    dimensions = okColumns.map((label,index) => ({
        label:label,
        values:groupedData.map(row => row.values[index])
    }))
    counts = groupedData.map(row => row.count)
    colors = groupedData.map(row => products.indexOf(row.values[row.values.length - 1]))

    var trace1 = {
        type: 'parcats',
        dimensions: dimensions,
        counts: counts,
        line:{color:colors,colorscale:"YlGnBu"},
        hoveron:"category",
        hoverinfo:"count+probability",
        arrangement:"freeform",
      };

      
    var data = [ trace1 ];
    
    var layout = {autosize:true};

    let nokComponent = "";
    if (nokColumns.length > 0){
        nokComponent = (
            <Typography>
                {nokColumns.map(col => <Chip label={col} className={classes.chipParcats} size="small"/>)} have no impact on the recommendation for this routine step
            </Typography>
        );
    }

    return (
        <div>
        <Plot
            data={data}
            layout={layout}
            className={classes.plot}
      />
        {nokComponent}
        </div>
    )
}
  

export default function StepExplorer(props){

    const classes = useStyles();

    // const [tableData, setTableData] = React.useState({
    //     "columnDefs":[],
    //     "rowData":[],
    // });

    // React.useEffect(() => {
    //     setTableData(props.data);
    // }, [props.data])

    var groupBy = function(xs, key) {
        return xs.reduce(function(rv, x) {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
      };
      

    const handleFilter = productName => () => {
        if (productName != "NO PRODUCT"){
            props.handleFilter(productName,props.step_name);
        }
    }

    let products = "";
    if ("products" in props){
        products = props.products.map(el => {

            console.log(el.productName);

            let name = el.productName === "null" ? "NO PRODUCT" : el.productName;
            
            return (
            <Grid item xs={2}>
                <Typography className={classes.title}>{name}</Typography>
                <Typography onClick={handleFilter(name)}><img src={el.img} className={classes.img}/></Typography>
                <Typography className={classes.desc}>{(el.count/el.totalCount * 100).toFixed(1)}%</Typography>
            </Grid>
            )
        }
        )
    }

    let remainingProducts = "";
    if ("remainingProducts" in props){
        if (props.remainingProducts.length > 0){
            remainingProducts = (
                <Grid item xs={2} className={classes.routine_step}>
                    <HtmlTooltip
                        title={
                        <React.Fragment>
                            <Typography color="inherit">Other recommended products</Typography>
                            <ul>
                                {props.remainingProducts.map(el => (
                                    <li>
                                        {el.productName} - <i>{(el.count/el.totalCount * 100).toFixed(1)}%</i>
                                    </li>
                                ))
                                }
                            </ul>
                        </React.Fragment>
                        }
                    >
                        <Button>+ {props.remainingProducts.length} other products recommended</Button>
                    </HtmlTooltip>
                </Grid>
            )
        }
    }

    const handleExplore = step_name => {
        setOpen(true);
    }

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    return (
        <div className={classes.root}>
        <Paper elevation={2}>
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            spacing={4}
        >
            <Grid item xs={3} className={classes.routine_step}>
                <Chip label={props.step_name}/>
                <Typography>
                <Fab variant="extended" size="medium" className={classes.button} onClick={() => handleExplore(props.step_name)}>
                    Explore
                </Fab>
                </Typography>
            </Grid>
            {products}
            {remainingProducts}
        </Grid>
        </Paper>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={classes.dialog}
            maxWidth={"lg"}
            fullWidth={true}
        >
            <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>{props.step_name}</DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description" className={classes.dialogContent}>
                <ParallelCategories parcatsData={props.parcatsData} step_name={props.step_name} products={props.products.concat(props.remainingProducts)}/>
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose} color="secondary" autoFocus>
                Close
            </Button>
            </DialogActions>
        </Dialog>
        </div>
    );


}