import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from '@material-ui/core/Grid';
// core components
import Fab from '@material-ui/core/Fab';
import Paper from '@material-ui/core/Paper';
import SendIcon from '@material-ui/icons/Send';
import { Box } from '@material-ui/core';
import cogoToast from 'cogo-toast';
import CircularProgress from '@material-ui/core/CircularProgress';

// Handson table
// Documentation at https://handsontable.com/docs/7.3.0/tutorial-introduction.html
import 'handsontable/dist/handsontable.full.css';
import { HotTable } from '@handsontable/react';

// Custom components
import { DataModelContext } from '../../layouts/Admin';
import { EnvironmentContext } from '../../layouts/Admin';
import StepExplorer from './StepExplorer';

const styles = {
  button:{
    // backgroundColor:"#00add0",
    // backgroundColor: "#21D4FD",
    // backgroundImage: "linear-gradient(19deg, #21D4FD 0%, #B721FF 100%)",
    background: "#00add0",
    background:'linear-gradient(223deg, rgba(0,104,125,1) 0%, rgba(0,173,208,1) 100%)',
    color:"white",
    marginRight:16,
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    // padding: 8,
    textAlign: 'center',
  },

  textTitle:{
    margin:"auto",
    fontWeight:300,
    textAlign:"left",
  },

  loading:{
    width:"100px",
    marginRight:32,
  }
};

const useStyles = makeStyles(styles);


export default function AnalysisView() {
  const classes = useStyles();

  // Context
  const [dataModel,setDataModel] = React.useContext(DataModelContext);
  const [env,setEnv] = React.useContext(EnvironmentContext);

  // States
  const [loading,setLoading] = React.useState(false);
  const [matrixData,setMatrixData] = React.useState({steps:[],columns:[],data:[],inputs:[]});
  const [selectedSteps,setSelectedSteps] = React.useState([]);
  const [textTitle,setTextTitle] = React.useState("Click on compute to calculate all prescriptions");

  const hotTable = React.useRef(null);


  React.useEffect(() => {
      if (matrixData["data"].length > 1){
          // let steps = createSteps(hotTable.current.hotInstance.getData());
          let steps = createSteps(matrixData["data"]);
          setSelectedSteps(steps);
          setTextTitle(matrixData["data"].length + " prescriptions")
        }

  }, [matrixData])

  const handleClearFilters = () => {
    if (matrixData["data"].length > 1){
      // const hooks = hotTable.current.hotInstance.hooks.getRegistered();
      // hooks.forEach(hook => {
      //   alert(hook);
      // })
      const filtersPlugin = hotTable.current.hotInstance.getPlugin('filters');
      // filtersPlugin.clearConditions();
      // filtersPlugin.addCondition(0, 'eq', ["0-20"]);
      // filtersPlugin.filter();
      filtersPlugin.clearConditions();
      filtersPlugin.filter();
      cogoToast.success("Cleared all filters in the table", { position: 'bottom-left'});

    }
  }

  const handleUpdate = () => {
    if (matrixData["data"].length > 1){

      let filteredData = hotTable.current.hotInstance.getData();
      let steps = createSteps(filteredData);
      setSelectedSteps(steps);
      setTextTitle(filteredData.length + " prescriptions")

    }

  }





  const handleExport = () => {
    if (matrixData["data"].length > 1){
      const exportPlugin = hotTable.current.hotInstance.getPlugin('exportFile');
      exportPlugin.downloadFile('csv', {
        filename: 'all_prescriptions_'+env,
        columnHeaders: true,
        columnDelimiter: ';',
      });
    } else {
      cogoToast.warn("Compute first all prescriptions before downloading the file", { position: 'bottom-left'});
    }

    // let exportPlugin = hotTable.current.hotInstance.setDataAtCell(0,0,'new value');
  }

  const handleFilter = (productName,stepName) => {
    const indexStep = matrixData["columns"].indexOf(stepName);
    const filtersPlugin = hotTable.current.hotInstance.getPlugin('filters');
    filtersPlugin.addCondition(indexStep, 'eq', [productName]);
    filtersPlugin.filter();
    cogoToast.success("Filter table on product "+productName, { position: 'bottom-left'});
  }

  const groupbyCount = (column,data) => {

    // Find values
    let indexCol = matrixData["columns"].indexOf(column);
    let values = data.map(el => el[indexCol]);

    // Count unique products
    let count = {}
    values.forEach(el => {
      if (el in count){
        count[el] += 1
      } else {
        count[el] = 1
      }
    })

    // Convert to array
    count = Object.keys(count).map(productName => ({
      productName:productName,
      count:count[productName],
      totalCount:values.length,
      img:dataModel.getImg(productName)
    }))

    // Sort array
    count = count.sort((a,b) => b.count - a.count)

    return count;
  }

  const handleSubmit = () => {
    setLoading(true);
  
    // Prepare API options and config
    var options = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Request-Headers': 'Content-Type, Authorization'
      },
    }

    // Parse inputs as user query
    let queryJSON = {"datamodel":dataModel}
    options["body"] = JSON.stringify(queryJSON);

    // API HTTP request
    fetch(process.env.REACT_APP_API_URL + 'app/prescription/all',options)
    .then(response => response.json())
    .then(data => {


        if (data["error"]){
          setLoading(false);
          cogoToast.error('Error: ' + data["message"],{ position: 'bottom-left'});
        } else {
          // Set matrix data for handson table
          let columns = data["data"]["columns"]["inputs"].concat(data["data"]["columns"]["steps"]);
          let rowData = data["data"]["data"].map(el => Object.values(el));
          setMatrixData({
            inputs:data["data"]["columns"]["inputs"],
            steps:data["data"]["columns"]["steps"],
            columns:columns,
            data:rowData,
          });
          setLoading(false);

          // let steps = createSteps(matrixData["data"]);
          // setSelectedSteps(steps);

          cogoToast.success("Computed all prescriptions successfully", { position: 'bottom-left'});
        }
    })
    .catch(function(error) {
        setLoading(false);
        cogoToast.error("There was an error when computing all prescription", { position: 'bottom-left'});
        console.log('Il y a eu un problème avec l\'opération fetch: ' + error.message);
    });

  }

  let settings = {
    data:matrixData["data"],
    colHeaders:matrixData["columns"],
    rowHeaders:true,
    dropdownMenu: true,
    filters: true,
    columnSorting: true,
    editor:false,
    width:"100%",
    height:"500",
    licenseKey: 'non-commercial-and-evaluation',
  }



  const createSteps = (data) => {
    return matrixData["steps"].map(el => {

        let allProducts = groupbyCount(el,data);
        let products = allProducts.slice(0,3);
        let remainingProducts = allProducts.slice(3);
        return (
          {
            step_name:el,
            products:products,
            remainingProducts:remainingProducts,
            parcatsData:{data:data,inputs:matrixData["inputs"],steps:matrixData["steps"]}
          }
          // <StepExplorer step_name={el} products={products} remainingProducts={remainingProducts}/>
        )
      })
  }

  // let steps = createSteps(matrixData["data"]);

  
   

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={12} className={classes.paper}>
          <Box display="flex" justifyContent="center">
            <Typography className={classes.textTitle}>{textTitle}</Typography>
            {loading?<CircularProgress className={classes.loading} color="secondary"/>:<span className={classes.loading}></span>}
            <Fab variant="extended" size="medium" className={classes.button} onClick={handleSubmit}>
              Compute
            </Fab>
            <Fab variant="extended" size="medium" className={classes.button} onClick={handleClearFilters}>
              Clear filters
            </Fab>
            <Fab variant="extended" size="medium" className={classes.button} onClick={handleExport}>
              Export to CSV
            </Fab>
          </Box>
        </Grid>
          {matrixData["data"].length < 1 ? 
          "" : 
          <Grid item xs={12} className={classes.paper}>
            <HotTable 
              settings={settings} 
              ref={hotTable}
              afterFilter={handleUpdate}
            />
          </Grid>
          }
        <Grid item xs={12} className={classes.paper}>
          {selectedSteps.map(el => (
            <StepExplorer 
              step_name={el.step_name} 
              products={el.products} 
              remainingProducts={el.remainingProducts} 
              parcatsData={el.parcatsData}
              handleFilter={handleFilter}
              />
          ))}
        </Grid>
        <Grid item xs={12} className={classes.paper}>
        </Grid>
      </Grid>
    </div>
  );
}
