import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Paper from '@material-ui/core/Paper';
import cogoToast from 'cogo-toast';
import { UserDataContext } from '../../index';
import ReactMarkdown from 'react-markdown';

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
  return images;
}

const images = importAll(require.context('assets/docs/img', false, /\.(png|jpe?g|svg)$/));

function Image(props) {
  return <img src={images[props.src.split("/").slice(-1)[0]]} style={{maxWidth: '100%',margin:"auto",textAlign:"center"}} />
}

const styles = {
    root:{
        paddingLeft:32,
        paddingRight:32,
        paddingTop:16,
        paddingBottom:16,
        fontSize:14,
    },
    title:{
        fontSize:16,
        fontWeight:700,
    },
};

const useStyles = makeStyles(styles);


export default function MarkdownPage(props) {

  

  const classes = useStyles();

  const [userData,setUserData] = React.useContext(UserDataContext);
  const [content,setContent] = React.useState("");

  React.useEffect(() => {

    let markdownContent = require(`assets/docs/${props.filepath}`);
    
    fetch(markdownContent)
    .then(res => res.text())
    .then(text => setContent(text))

    }, [props])



  return (
    <Paper className={classes.root}>
      <ReactMarkdown 
        source={content}
        escapeHtml={false}
        renderers={{image: Image}}
        // transformImageUri={uri => process.env.PUBLIC_URL + uri.split("/").slice(-1)[0]}
        />
    </Paper>
  );
}
