import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import cogoToast from 'cogo-toast';

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import StyledButton from './StyledButton';

const projects = [{'value': 'loreal_skindr', 'label': 'SkinDr'}, {'value': 'lorealparis_hairdiag', 'label': "L'Oréal Paris Hairdiag"}]


const styles = {
    root:{
    },
    textField:{
        marginRight:16,
        marginBottom:16,
    },
    content:{
        fontWeight:300,
    }

};

const useStyles = makeStyles(styles);





export default function CreateNewAirtableMapping(props) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [formData, setFormData] = React.useState({});

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const title = "Create new airtable mapping";

  const [project, setProjectCode] = React.useState("");
  const handleChangeProject = event => {
    setProjectCode(event.target.value);
    handleChange(event);
  };

  const handleChange = (event) => {
      // pluralsight.com/guides/handling-multiple-inputs-with-single-onchange-handler-react
      const value = event.target.value;
      setFormData({
        ...formData,
        [event.target.name]: value
      });
  }

  const handleCreate = () => {

    // Prepare API options and config
    var options = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Request-Headers': 'Content-Type, Authorization'
      },
    }

    options["body"] = JSON.stringify(formData);
    // API HTTP request
    fetch(process.env.REACT_APP_API_URL + 'app/config/airtable',options)
    .then(response => response.json())
    .then(data => {

        if (data["error"] == false){
            cogoToast.success(data["message"], { position: 'bottom-left'});
        } else {
            cogoToast.error(data["message"], { position: 'bottom-left'});
        }

        handleClose();

    })
    .catch(function(error) {
        cogoToast.error("Error, see console for more info", { position: 'bottom-left'});
        console.log('Il y a eu un problème avec l\'opération fetch: ' + error.message);
        handleClose();

    });
  }



  return (
    <span>
      <Card>
        <CardBody>
          <DialogContent>
            <DialogContentText className={classes.content}>
            Link an environment name to an Airtable ID.
              <ul>
                <li>Create a new airtable mapping linking an environment (often written "brand-country-touchpoint" eg: lorealparis-usa or biotherm-chn-tmall) and an Airtable ID which is basically you Airtable API key.</li>
                <li>Ask Ekimetrics if you are not sure of the format.</li>
                <li>Example: brand = lorealparis ; localization = fra ; project = loreal_skindr ; airtable ID =  app123456789</li>
              </ul>
            </DialogContentText>
            <form noValidate autoComplete="off">
              <TextField className={classes.textField} name="brand" label="brand" required variant="outlined" onChange={handleChange}/>
              <TextField className={classes.textField} name="localization" label="localization" required variant="outlined" onChange={handleChange} helperText="Country ISO3 code (-language or -touchpoint)"/>
              <TextField 
                className={classes.textField} 
                name="project_name" 
                label="project" 
                select 
                variant="outlined" 
                onChange={handleChangeProject}
                helperText="Please Select your project"
              >
                {projects.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
              <TextField className={classes.textField} name="airtable_id" label="airtable ID" required variant="outlined" onChange={handleChange} helperText="Airtable API key"/>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCreate} color="primary">
                Create
            </Button>
          </DialogActions>
        </CardBody>
      </Card>
    </span>
  );
}
