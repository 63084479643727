import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Fab from '@material-ui/core/Fab';
import SendIcon from '@material-ui/icons/Send';
import ShuffleIcon from '@material-ui/icons/Shuffle';
import { Box } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import cogoToast from 'cogo-toast';

// DataModel
import { DataModelContext } from '../../layouts/Admin';
import { EnvironmentContext } from '../../layouts/Admin';
import { UserDataContext } from '../../index';
import FeedbackForm from './FeedbackForm';



const styles = theme => ({

  button:{
    backgroundColor:"#00add0",
    color:"white",
    margin:8,
  },

  resize:{
    fontSize:'14px',
  },

  buttonYes:{
    color:"white",
    margin:8,
    backgroundColor:"#2ecc71",
  },

  buttonNo:{
    color:"white",
    margin:8,
    backgroundColor:"#ff7474",
  },

  formControl: {
    margin: 8,
    minWidth: "90%",
    opacity:"1 !important",
  },

  margin: {
    margin: 8,
  },

  leftPanel: {
    margin:"auto",
    width:"80%",
  },

  select: {
    '&:before': {
        borderColor: "#c7c6c2",
    },
    '&:after': {
        borderColor: "#c7c6c2",
    }
  }

});

const useStyles = makeStyles(styles);

function InputSelector(props){

    const classes = useStyles();
    const [value, setValue] = React.useState(props.value);

    React.useEffect(() => {
        setValue(props.value);
        props.onChange(props.text,props.value);
    }, [props.value])

    function handleChange(event) {
      setValue(event.target.value);
      props.onChange(props.text,event.target.value);
    }

    return (
    <FormControl className={classes.formControl}>
        <InputLabel shrink htmlFor="input">{props.text}</InputLabel>
        <Select
        value={value}
        onChange={handleChange}
        className={classes.select}
        displayEmpty
        inputProps={{
            name: 'input',
            id: 'input',
            classes:{select:classes.resize}
        }}
        >
        {
          props.options.sort().map(el => {
            return <MenuItem value={el}>{el}</MenuItem>
          })
        }
        </Select>
    </FormControl>
    )
}

export default function PrescriptionForm(props){

  const classes = useStyles();

  // Context and state
  const [dataModel,setDataModel] = React.useContext(DataModelContext);
  const [env,setEnv] = React.useContext(EnvironmentContext);
  const [userData,setUserData] = React.useContext(UserDataContext);
  const [values,setValues] = React.useState([]);
  const [query,setQuery] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [feedbackOpen, setFeedbackOpen] = React.useState(false);

  React.useEffect(() => {

    // Set all possible question values
    setValues(dataModel.getQuestionsValues());

  },[dataModel]);


  React.useEffect(() => {

    // Set initial values
    let initQuery = {}
    values.forEach(el => {
      initQuery[el.question_name] = el.values[0];
    })
    setQuery(initQuery);

  },[values]);


  const sendYesFeedback = () => {

    if (props.products.length > 0){
      sendFeedback("",true);
    } else {
      cogoToast.warn("You cannot send a feedback before running first the algorithm by clicking on submit or shuffle", { position: 'bottom-left'});
    }
  }

  const sendNoFeedback = () => {
    if (props.products.length > 0){
      setFeedbackOpen(true);
    } else {
      cogoToast.warn("You cannot send a feedback before running first the algorithm by clicking on submit or shuffle", { position: 'bottom-left'});
    }
  }


  const sendFeedback = (message,positive=true,selection={}) => {

    // Prepare API options and config
    var options = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Request-Headers': 'Content-Type, Authorization'
      },
    }

    // Parse inputs as user query
    let queryJSON = {
      "query":query,
      "products":props.products,
      "environment":env,
      "username":userData["username"],
      "message":message,
      "positive":positive,
      "selection":selection,
    }
    options["body"] = JSON.stringify(queryJSON);

    // API HTTP request
    console.log("Requesting at ")
    fetch(process.env.REACT_APP_API_URL + 'app/feedback',options)
    .then(response => response.json())
    .then(data => {
        cogoToast.success("Feedback successfully sent to Ekimetrics", { position: 'bottom-left'});
        // handleShuffle();
        console.log(data);
    })
    .catch(function(error) {
        cogoToast.error("There was an error when sending feedback to Ekimetrics", { position: 'bottom-left'});
        console.log('Il y a eu un problème avec l\'opération fetch: ' + error.message);
    });

  }

  // Submit function
  const handleSubmit = () => {

    setLoading(true);

    // // Change color to gray
    // if (isSubmitOnly){
    //   await this.props.changeColor("gray");
    // }

    // Prepare API options and config
    var options = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Request-Headers': 'Content-Type, Authorization'
      },
    }

    // Parse inputs as user query
    let queryJSON = {"query":query,"datamodel":dataModel}
    options["body"] = JSON.stringify(queryJSON);

    // Choose correct environment
    let params = dataModel.buildEnvironmentParams();

    // API HTTP request
    console.log("Requesting at ")
    console.log(process.env.REACT_API_URL)
    fetch(process.env.REACT_APP_API_URL + 'app/prescription?' + params,options)
    .then(response => response.json())
    .then(data => {

      if (data["error"]){
        setLoading(false);
        cogoToast.error('Error: ' + data["message"],{ position: 'bottom-left'});
      } else {
        console.log(data);  
        props.onProductsChange(data["data"]);
        setLoading(false);
      }
    })
    .catch(function(error) {
      console.log('Il y a eu un problème avec l\'opération fetch: ' + error.message);
      setLoading(false);
    });

  }


  // Shuffle Inputs function
  const handleShuffleInputs = () => {

    // Helper function to take a random element from an array
    function randomChoice(arr) {
      return arr[Math.floor(arr.length * Math.random())];
    }

    // Select random inputs 
    let newQuery = {};
    values.forEach(el => {
      newQuery[el.question_name] = randomChoice(el.values);
    })
    setQuery(newQuery);
  }

  // Click on Shuffle button
  const handleShuffle = async () => {
    await handleShuffleInputs();
    handleSubmit();
  }

  // Helper function to change state on dropdown change
  const handleFieldChange = (name , value) => {
    let newQuery = query;
    newQuery[name] = value;
    setQuery(newQuery);
    console.log(query);
  }

  // const handleAlert = () => {
  //   cogoToast.success("Hellooooo");
  //   cogoToast.info("This is an info message", { position: 'top-center', heading: 'Information' });
  // }


  // Render
  return (
      <form autoComplete="on" className = {classes.leftPanel}>


          {
            values.map(el => {
              let value = Object.keys(query).length === 0 ? "" : query[el.question_name];
              return <InputSelector 
                key = {el.question_name} 
                text={el.question_name} 
                options={el.values} 
                value={value} 
                onChange={handleFieldChange}/>;
            })
          }
        
          
          <Box display="flex" justifyContent="center">
            <Fab variant="extended" size="medium" className={classes.button} onClick={handleSubmit}>
              <SendIcon className={classes.margin} />
              Submit
            </Fab>
          </Box>
          <Box display="flex" justifyContent="center">
            <Fab variant="extended" size="medium" className={classes.button} onClick={handleShuffle}>
              <ShuffleIcon className={classes.margin} />
              Shuffle
            </Fab>
          </Box>
          {/* <Box display="flex" justifyContent="center">
            <Fab variant="extended" size="medium" color="secondary" className={classes.button} onClick={handleAlert}>
              <ShuffleIcon className={classes.margin} />
              Click
            </Fab>
          </Box> */}
          <Box display="flex" justifyContent="center">
            {/* <FeedbackForm send={this.sendSwipeInfo} products={this.props["products"]} inputs={this.state["inputs"]} open={this.state.feedbackOpen}/> */}
            <Fab size="medium" className={classes.buttonNo} onClick={sendNoFeedback} style={props.products.length === 0 ? {backgroundColor:"#d5d5d5"}:{}}>
              <ClearIcon className={classes.margin} />
            </Fab>
            <Fab size="medium" className={classes.buttonYes} onClick={sendYesFeedback} style={props.products.length === 0 ? {backgroundColor:"#d5d5d5"}:{}}>
              <DoneIcon className={classes.margin} />
            </Fab>
          </Box>
          <Box display="flex" justifyContent="center">
            {loading?<CircularProgress color="secondary" style={{marginTop:"16px"}}/>:""}
          </Box>
          <FeedbackForm sendFeedback={sendFeedback} products={props.products} open={feedbackOpen} setFeedbackOpen={setFeedbackOpen}/>
          {/* <InputSelector text="availability" options={["2019","2020"]} value={"2019"} onChange={this.handleAvailabilityChange}/> */}
      </form>    
  )


}