import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import documentationImg from "assets/img/documentation.png";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const styles = {
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative"
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px"
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: 4,
    textAlign: 'center',
    fontWeight: "400",
    fontSize: "13px",
  },
};

const useStyles = makeStyles(styles);

export default function TypographyPage() {
  const classes = useStyles();
  return (
    <Card>
      <CardHeader color="primary">
        <h4 className={classes.cardTitleWhite}>Documentation</h4>
        <p className={classes.cardCategoryWhite}>
          Access Ekimetrics SkinDr documentation
        </p>
      </CardHeader>
      <CardBody>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={3}>
            <Typography className={classes.paper}>
              <p><b>You need more information about the Product Prescription process ? or the different tools ?</b></p>
              <br/>
              <p>Find all information in the Ekimetrics SkinDr documentation available at <a href="https://eki-skindr-docs.herokuapp.com">https://eki-skindr-docs.herokuapp.com</a></p>
              <p>Username/password: ekimetrics/ekimetrics</p>
              <br/>
              <p>If you have any other questions, send us an email at <a href="mailto:skindr@ekimetrics.com">skindr@ekimetrics.com</a></p>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={9}>
            <img
              className={classes.cardImgTop}
              data-src="holder.js/100px180/"
              alt="100%x180"
              style={{width: "100%", display: "block" }}
              src = {documentationImg}
              // data-holder-rendered="true"
            />
          </Grid>
        </Grid>

      </CardBody>
    </Card>
  );
}
